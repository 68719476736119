import * as React from "react";
import * as Flickity from "flickity";
import FlickityCarouselContext from "./FlickityCarousel.Context";

type Props = {
    className?: string;
    to: "prev" | "next";
};

const FlickityCarouselNavigationButton: React.FunctionComponent<Props> = ({
    children,
    className,
    to,
}) => {
    const context = React.useContext(FlickityCarouselContext);
    if (context === null)
        throw new Error("missing wrapping FlickityCarousel context");
    const handleClick = React.useCallback(() => {
        if (context.instance) {
            switch (to) {
                case "next":
                    context.instance.next();
                    break;
                case "prev":
                    context.instance.previous();
                    break;
            }
        }
    }, [context]);

    return (
        <button type="button" onClick={handleClick} className={className}>
            {children}
        </button>
    );
};

export default FlickityCarouselNavigationButton;
