import * as React from "react";
import classnames from "classnames";
import { Content } from "core/models/modules/Content";
import ReadMoreButton from "view/components/ReadMoreButton";
import Button from "view/components/Button";
import useWindowWidth from "view/hooks/useWindowWidth";
import Image from "view/components/Image";
import { breakpoints } from "core/constants";
import Container from "view/components/Container";
import ModuleWrapper from "view/components/ModuleWrapper";
import Title from "view/components/Title";
import $ from "./ContentRow.module.scss";

const ContentRow = (props: Content) => {
    const {
        image,
        title,
        titleUnderline,
        text,
        ctaLabel,
        ctaUrl,
        ctaStyle,
        reversed,
    } = props;
    const baseStyles = classnames([$.base, reversed && $.baseReversed]);
    const contentStyles = classnames([
        $.content,
        reversed && $.contentReversed,
    ]);
    const titleStyle = classnames(
        $.title,
        titleUnderline !== "default" && $.titleLine,
        titleUnderline === "gold" && $.titleLineGold,
        titleUnderline === "blue" && $.titleLineBlue,
    );

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.mobile;
    const imgWidth = isMobile ? 710 : 528;

    return (
        <ModuleWrapper>
            <section data-testid="content-block">
                <Container className={baseStyles}>
                    {image && image.url && (
                        <div className={$.imgWrap} data-testid="image-wrap">
                            <Image
                                placeholder={`${image.url}?w=30&q=50`}
                                src={`${image.url}?w=${imgWidth}&q=80`}
                                alt={image.title}
                                className={$.img}
                            />
                        </div>
                    )}
                    <div className={contentStyles}>
                        {!!title && (
                            <Title className={titleStyle}>{title}</Title>
                        )}
                        {!!text && (
                            <div
                                className={$.text}
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        )}
                        {ctaLabel && ctaUrl && (
                            <>
                                {ctaStyle !== "arrow" ? (
                                    <ReadMoreButton
                                        text={ctaLabel}
                                        link={ctaUrl}
                                    />
                                ) : (
                                    <Button
                                        text={ctaLabel}
                                        link={ctaUrl}
                                        className={$.button}
                                        navy
                                    />
                                )}
                            </>
                        )}
                    </div>
                </Container>
            </section>
        </ModuleWrapper>
    );
};

export default ContentRow;
