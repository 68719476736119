import * as React from "react";
import createDebounce from "core/utils/debounce";
import { FaqIcon } from "..";
import $ from "./FaqItem.module.scss";

interface Props {
    question: string;
    answer: string;
}

const FaqItem = (props: Props) => {
    const elementRef = React.createRef<HTMLDivElement>();
    const [open, setOpen] = React.useState(false);
    const [height, setHeight] = React.useState<undefined | string | number>();
    const { question, answer } = props;

    const resizeDebounce = createDebounce(200);
    const handleResize = () => {
        resizeDebounce(() => {
            setOpen(false);
            setItemHeight();
        });
    };
    const setItemHeight = () => {
        const el = elementRef.current;
        const faqItemHeight = el ? el.scrollHeight : "auto";

        if (open) return setHeight(faqItemHeight);
        setHeight("0px");
    };

    React.useEffect(() => {
        setItemHeight();
    }, [open]);

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <section className={$.base}>
            <button
                onClick={() => setOpen(!open)}
                className={$.toggleButton}
                data-testid="button"
            >
                <FaqIcon isPlus={open} />
                <h3 className={$.question}>{question}</h3>
            </button>
            <section
                className={$.answerWrap}
                ref={elementRef}
                style={{ height }}
                data-testid="answer-wrap"
            >
                <div
                    className={$.answer}
                    data-testid="answer"
                    dangerouslySetInnerHTML={{ __html: answer }}
                />
            </section>
        </section>
    );
};

export default FaqItem;
