import * as React from "react";
import classnames from "classnames";
import TweenLite, { TimelineLite } from "gsap";
import AppContext from "view/context/AppContext";
import { Image, BackgroundVideo } from "view/components";
import useWindowWidth from "view/hooks/useWindowWidth";
import Analytics from "core/services/analytics";
import $ from "./Header.module.scss";

const Header: React.FunctionComponent = () => {
    const containerRef = React.useRef(null);
    const containerPlaceholderRef = React.useRef(null);
    const logo = React.useRef(null);
    const appContext = React.useContext(AppContext);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);
    if (!appContext) return null;
    const { setShowHeaderLogo, init } = appContext;
    const {
        primaryColorBackground,
        image,
        imageMobile,
        logoHeader,
        primaryColorFont,
        tagline,
        mainMenuStyle,
        homepageHeaderTextColor,
        videoHeader,
    } = init.configuration;
    const initialHeaderTween = new TimelineLite({ paused: true });
    let headerHeight = 0;
    const isCenter = mainMenuStyle === "align-center";
    const backgroundImage = window.innerWidth >= 768 ? image : imageMobile;
    const containerStyle = {
        backgroundColor: primaryColorBackground,
        color: primaryColorFont,
    };
    const buttonStyle = homepageHeaderTextColor
        ? {
              color: homepageHeaderTextColor,
          }
        : undefined;
    const svgStyle = homepageHeaderTextColor
        ? {
              fill: homepageHeaderTextColor,
          }
        : undefined;

    const windowWidth = useWindowWidth();
    const imgWidth = windowWidth;

    const setHeaderHeight = () => {
        const container = containerRef.current;
        const containerPlaceholder = containerPlaceholderRef.current;
        if (container && containerPlaceholder) {
            // constants
            const menuHeight = {
                mobile: isCenter ? 72 : 70,
                desktop: isCenter ? 140 : 100,
            };
            const isDesktop = window.innerWidth >= 768;
            // set header height
            const currentMenuHeight = isDesktop
                ? menuHeight.desktop
                : menuHeight.mobile;
            headerHeight = window.innerHeight - currentMenuHeight;
            // initial header animation
            initialHeaderTween
                .set(container, { height: headerHeight })
                .set(containerPlaceholder, { height: headerHeight });
        }
    };

    const initialTween = () => {
        const container = containerRef.current;
        const containerPlaceholder = containerPlaceholderRef.current;
        const logoElement = logo.current;
        if (container && containerPlaceholder && logoElement) {
            const halfHeaderHeight = headerHeight / 1.5;

            initialHeaderTween
                // @TODO fourth argument "Stagger" needs to have a value according its types
                .staggerTo([container, containerPlaceholder], 0.5, {
                    height: halfHeaderHeight,
                    delay: 1,
                })
                .to(logoElement, 0.5, { scale: 0.8 }, "-=0.5")
                .paused(false);

            // update headerHeight after animation
            headerHeight = halfHeaderHeight;
        }
    };

    const changeHeaderHeight = (scrollTop: number) => {
        if (scrollTop >= headerHeight) {
            return;
        }

        const scrolled = (headerHeight - scrollTop) / headerHeight;
        const scale = 0.8 - (1 - scrolled);
        // get half height of current height
        const border = headerHeight / 4;
        // toggle fade image
        const showHeaderLogo = scrollTop >= border;
        TweenLite.to(logo.current, 0.5, { opacity: showHeaderLogo ? 0 : 1 });
        TweenLite.to(logo.current, 0.1, { y: -scrollTop, scale });
        setShowHeaderLogo(showHeaderLogo);
    };

    const scrollToEndOfModule = () => {
        const button = buttonRef.current;
        if (button) {
            const endOfModule = button.getBoundingClientRect().bottom;

            Analytics.event("header_scroll_down_button", "click", tagline);

            window.scroll({
                top: endOfModule,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const handleScroll = (event: Event) => {
        const scrollTop = window.scrollY || window.pageYOffset;
        changeHeaderHeight(scrollTop);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        setShowHeaderLogo(false);
        setHeaderHeight();
        initialTween();

        return () => {
            window.removeEventListener("scroll", handleScroll);
            setShowHeaderLogo(true);
        };
    }, []);

    return (
        <>
            <section
                ref={containerRef}
                className={classnames([
                    $.container,
                    isCenter && $.containerSmall,
                ])}
                style={containerStyle}
            >
                {videoHeader && videoHeader.url ? (
                    <BackgroundVideo videoUrl={videoHeader.url} />
                ) : (
                    <Image
                        placeholder={`${backgroundImage.url}?w=30&q=50`}
                        src={`${backgroundImage.url}?w=${imgWidth}&q=80`}
                        alt={backgroundImage.title}
                        className={$.backgroundImage}
                        background
                    />
                )}

                <div
                    ref={logo}
                    className={$.imageWrap}
                    data-testid="logoContainer"
                >
                    {logoHeader && logoHeader.url && (
                        <img
                            className={$.image}
                            src={logoHeader.url}
                            alt="tagline"
                        />
                    )}
                </div>

                <button
                    ref={buttonRef}
                    className={$.more}
                    onClick={scrollToEndOfModule}
                    data-testid="readMoreButton"
                    style={buttonStyle}
                >
                    <svg width="65" height="65">
                        <defs>
                            <filter
                                x="-35.6%"
                                y="-31.1%"
                                width="171.1%"
                                height="171.1%"
                                filterUnits="objectBoundingBox"
                                id="a"
                            >
                                <feOffset
                                    dy="2"
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                />
                                <feGaussianBlur
                                    stdDeviation="5"
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.226917614 0"
                                    in="shadowBlurOuter1"
                                />
                            </filter>
                            <path
                                d="M22.5 45C10.074 45 0 34.926 0 22.5S10.074 0 22.5 0 45 10.074 45 22.5 34.926 45 22.5 45zm0-19.621l-6.44-6.44a1.5 1.5 0 00-2.12 2.122l8.56 8.56 8.56-8.56a1.5 1.5 0 00-2.12-2.122l-6.44 6.44z"
                                id="b"
                            />
                        </defs>
                        <g
                            transform="translate(10 8)"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                            <use fill="#FFF" xlinkHref="#b" style={svgStyle} />
                        </g>
                    </svg>
                    {tagline}
                </button>

                <svg
                    className={$.mask}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                >
                    <defs>
                        <mask id="myMask" x="0" y="0" width="100" height="100">
                            <rect width="100" height="100" fill="white" />
                            <circle cx="50" cy="20" r="70" fill="black" />
                        </mask>
                    </defs>

                    <rect
                        x="0"
                        y="0"
                        width="100"
                        height="100"
                        fill="white"
                        mask="url(#myMask)"
                    />
                </svg>
            </section>

            <section
                ref={containerPlaceholderRef}
                className={classnames([
                    $.containerPlaceholder,
                    isCenter && $.containerSmall,
                ])}
            />
        </>
    );
};

export default Header;
