import { FormFields } from "core/models";

export function rewardsFields(
    descriptionPlaceholderText?: string,
    postalCodeRequired?: boolean,
    eanCodeRequired?: boolean,
    descriptionRequired?: boolean,
    phoneNumberRequired?: boolean,
): {
    title?: string;
    name?: string;
    fields: FormFields[];
}[] {
    return [
        {
            title: "Jouw gegevens",
            name: "Gegevens",
            fields: [
                {
                    type: "text",
                    name: "firstname",
                    placeholder: "Voornaam",
                    required: true,
                    order: 3,
                },
                {
                    type: "text",
                    name: "lastname",
                    placeholder: "Achternaam",
                    required: true,
                    order: 4,
                },
                {
                    type: "date",
                    name: "birthdate",
                    placeholder: "Geboortedatum",
                    required: true,
                    order: 5,
                },
                {
                    type: "email",
                    name: "email",
                    placeholder: "E-mail",
                    required: true,
                    order: 6,
                },
                {
                    type: "tel",
                    name: "phone",
                    placeholder: "Telefoonnummer",
                    required: phoneNumberRequired ?? false,
                    order: 7,
                },
                {
                    type: "text",
                    name: "eanCode",
                    placeholder: "Voer hier jouw EAN-code in",
                    labelText: "EAN-code",
                    required: eanCodeRequired ?? false,
                    order: 1,
                },
                {
                    type: "textarea",
                    name: "description",
                    placeholder:
                        descriptionPlaceholderText ??
                        "Waarom moet jij deze actie winnen?",
                    required: descriptionRequired ?? false,
                    order: 2,
                },
            ],
        },
        {
            name: "Adresgegevens",
            fields: [
                {
                    type: "postcode",
                    name: "postalcode",
                    placeholder: "Postcode",
                    required: postalCodeRequired ?? false,
                    order: 11,
                },
                {
                    type: "text",
                    name: "street",
                    placeholder: "Straat",
                    required: true,
                    order: 8,
                },
                {
                    type: "text",
                    name: "housenumber",
                    placeholder: "Huisnummer",
                    required: true,
                    order: 9,
                },
                {
                    type: "text",
                    name: "housenumberaddition",
                    placeholder: "Toevoeging",
                    required: false,
                    order: 10,
                },
                {
                    type: "text",
                    name: "city",
                    placeholder: "Woonplaats",
                    required: true,
                    order: 12,
                },
            ],
        },
    ];
}
