import * as React from "react";
import classnames from "classnames";
import AppContext from "view/context/AppContext";
import $ from "./FaqIcon.module.scss";

interface Props {
    isPlus: boolean;
}

const FaqIcon = (props: Props) => {
    const { isPlus } = props;
    const appContext = React.useContext(AppContext);
    let ctaColor;
    if (appContext && appContext.init && appContext.init.configuration) {
        const {
            init: { configuration },
        } = appContext;
        if (configuration) {
            ({ ctaColor } = configuration);
        }
    }
    const iconStyle = {
        "--primary-color-font": ctaColor,
    };

    const baseStyles = classnames([$.base, isPlus && $.baseGrey]);
    const iconVertStyles = classnames([
        $.iconRect,
        isPlus && $.iconRectHorizontal,
    ]);
    return (
        <div className={baseStyles} style={iconStyle} data-testid="wrap">
            <svg viewBox="0 0 15 15" className={$.icon}>
                <g fill="#012169" fillRule="evenodd">
                    <rect
                        x="6"
                        width="3"
                        height="15"
                        rx="1.5"
                        className={iconVertStyles}
                        data-testid="rect"
                    />
                    <rect
                        transform="rotate(90 7.5 7.5)"
                        x="6"
                        width="3"
                        height="15"
                        rx="1.5"
                    />
                </g>
            </svg>
        </div>
    );
};

export default FaqIcon;
