import * as React from "react";
import classnames from "classnames";
import useWindowWidth from "view/hooks/useWindowWidth";
import { breakpoints } from "core/constants";
import { Video as VideoModel } from "core/models";
import ModuleWrapper from "view/components/ModuleWrapper";
import Container from "view/components/Container";
import Image from "view/components/Image";
import Title from "view/components/Title";
import $ from "./Video.module.scss";

const Video: React.FunctionComponent<VideoModel> = (props: VideoModel) => {
    const { title, videoId, image, icon, style, moduleType, video, videoType } =
        props;
    const [showVideo, setShowVideo] = React.useState(false);
    const videoElementRef = React.useRef<HTMLVideoElement | null>(null);
    const clickHandler = React.useCallback(() => {
        setShowVideo(true);
    }, []);
    const backgroundImage = image && image.url;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.mobile;
    const imgWidth = isMobile ? 768 : 1120;
    const playIcon = icon && icon.url;
    const autoplay = backgroundImage ? 1 : 0;
    const videoStyle = classnames($.video, style === "card" && $.videoCard);

    React.useEffect(() => {
        const videoElement = videoElementRef.current;
        if (showVideo && videoElement) {
            videoElement.play();
        }
    }, [showVideo]);

    const videoElement = (
        <div className={$.videoWrapper}>
            {!showVideo && backgroundImage && (
                <div onClick={clickHandler}>
                    <div
                        className={$.poster}
                        style={{ backgroundImage: `url(${backgroundImage})` }}
                    />
                    <Image
                        placeholder={`${backgroundImage}?w=30&q=50`}
                        src={`${backgroundImage}?w=${imgWidth}&q=80`}
                        alt={image && image.title}
                        background
                    />
                    {playIcon ? (
                        <span
                            className={$.icon}
                            style={{ backgroundImage: `url(${playIcon})` }}
                        />
                    ) : (
                        <span className={classnames($.icon, $.iconDefault)} />
                    )}
                </div>
            )}
            {(showVideo || !backgroundImage) &&
                videoType === "upload" &&
                video && (
                    <video
                        ref={videoElementRef}
                        controls
                        className={$.uploadedVideo}
                    >
                        <source src={video.url} />
                    </video>
                )}
            {(showVideo || !backgroundImage) && videoType === "youtube" && (
                <iframe
                    data-testid="youtubeVideo"
                    title={`Vrumona video - ${title}`}
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay}&enablejs=1&rel=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
        </div>
    );

    if (style === "full-width") {
        return videoElement;
    }

    return (
        <ModuleWrapper moduleType={moduleType}>
            <Container>
                <div className={videoStyle}>
                    {title && <Title className={$.title}>{title}</Title>}
                    {videoElement}
                </div>
            </Container>
        </ModuleWrapper>
    );
};

export default Video;
