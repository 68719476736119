import * as React from "react";
import classnames from "classnames";

interface Props {
    className?: any;
}

const Loader = ({ className }: Props) => (
    <div className={classnames("loader", className)}>
        <div className="loaderRing">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Loader;
