import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Analytics from "core/services/analytics";

export default function useHistory(props: RouteComponentProps) {
    const { history } = props;

    React.useEffect(() => {
        const unsub = history.listen((location: any) => {
            Analytics.pageView(location.pathname);
            window.scrollTo(0, 0);
        });

        return () => {
            unsub();
        };
    }, [history]);
}
