import { Module } from "./Module";
import { ProductSlide } from "./modules/ProductSlider";
import { PageParams } from "./Page";

interface Category {
    slug: string;
    title: string;
}

export interface ProductParams extends ProductSlide, PageParams {
    id: string;
    category: Category;
}

export class Product {
    id: string;

    title: string;

    slug: string;

    contentType: string;

    moduleReferences: Module[] | undefined;

    seoDescription: string;

    seoKeywords: string;

    constructor(data: ProductParams) {
        const {
            id,
            title,
            slug,
            contentType,
            color,
            moduleReferences = [],
            style,
            shopAvailability,
            nutritionalValues,
            titlePrefix,
            ctaLabel,
            seoDescription,
            seoKeywords,
            text,
            image,
            imageDetail: imageProductdetail,
            ingredients,
            analysis,
        } = data;
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.contentType = contentType;
        this.seoDescription = seoDescription;
        this.seoKeywords = seoKeywords;
        const ProductSliderType: "productSlider" = "productSlider";
        const imageDetail =
            imageProductdetail && imageProductdetail.url
                ? imageProductdetail
                : null;
        const productSliderModule: Module = {
            moduleType: ProductSliderType,
            title,
            style,
            id,
            contentType: "module",
            products: [
                {
                    title,
                    style,
                    slug,
                    contentType,
                    titlePrefix,
                    text,
                    image,
                    imageDetail,
                    ingredients,
                    nutritionalValues,
                    shopAvailability,
                    analysis,
                    ctaLabel,
                    color,
                },
            ],
        };
        const modules = [productSliderModule, ...moduleReferences];
        this.moduleReferences = modules;
    }
}
