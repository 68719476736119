import * as React from "react";
import CarouselContext from "./Carousel.Context";

type Props = {
    className?: string;
    to: "prev" | "next";
};

const CarouselItem: React.FunctionComponent<Props> = (props) => {
    const { className, children, to } = props;
    const context = React.useContext(CarouselContext);
    if (context === null) throw new Error("missing context");
    const onClickHandler = React.useCallback(() => {
        if (to === "next") {
            context.navigateNext();
        } else if (to === "prev") {
            context.navigatePrev();
        }
    }, [to]);

    return (
        <button type="button" className={className} onClick={onClickHandler}>
            {children}
        </button>
    );
};

export default CarouselItem;
