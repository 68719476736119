import * as React from "react";
import classnames from "classnames";
import AppContext from "view/context/AppContext";
import $ from "./Hamburger.module.scss";

interface Props {
    onClick: () => void;
    className: string | undefined;
    active: boolean;
}

const Hamburger = ({ onClick, className, active }: Props) => {
    const appContext = React.useContext(AppContext);
    if (!appContext) return null;
    let menuColorText: string | undefined;
    const { init } = appContext;
    if (init) {
        const { configuration } = init;
        if (configuration) {
            ({ menuColorText } = configuration);
        }
    }
    const buttonClassnames = classnames([
        $.hamburger,
        className,
        active ? $.isActive : null,
    ]);
    const buttonStyle = {
        color: menuColorText,
    };
    const innerStyle = {
        backgroundColor: menuColorText,
    };

    return (
        <button
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
            onClick={onClick}
            className={buttonClassnames}
            style={buttonStyle}
        >
            <span className={$.label}>menu</span>
            <span className={$.box}>
                <span className={$.innerBefore} style={innerStyle} />
                <span className={$.inner} style={innerStyle} />
                <span className={$.innerAfter} style={innerStyle} />
            </span>
        </button>
    );
};

export default Hamburger;
