import * as React from "react";
import {
    ModuleWrapper,
    Content,
    Faq,
    Form,
    HtmlCode,
    Video,
    Container,
} from "view/components";
import { Module } from "core/models";
import { addLeadingSlash } from "history/PathUtils";
import $ from "./ModuleHandler.module.scss";
import ProductCategorySlider from "../ProductCategorySlider";
import Hero from "../Hero";
import Rewards from "../Rewards";

interface Props {
    modules: Module[];
    pageTitle: string;
    pageTitleVisible: boolean;
}

const renderModule = (module: Module, index: number) => {
    switch (module.moduleType) {
        case "productSlider":
            return <ProductCategorySlider key={index} {...module} />;
        case "productCategorySlider":
            return <ProductCategorySlider key={index} {...module} />;
        case "contact":
            return <Form key={index} {...module} />;
        case "faq":
            return <Faq key={index} {...module} />;
        case "content":
            return Content({ ...module });
        case "htmlCode":
            return <HtmlCode key={index} {...module} />;
        case "video":
            return <Video {...module} />;
        case "hero":
            return <Hero {...module} />;
        case "rewards":
            return <Rewards key={index} {...module} />;
        default:
            console.debug("Module not found:", module.title);
            return null;
    }
};

const ModuleHandler = ({ modules, pageTitle, pageTitleVisible }: Props) => {
    const showPageTitle: boolean =
        !pageTitleVisible ||
        modules[0].moduleType === "productCategorySlider" ||
        modules[0].moduleType === "productSlider" ||
        (modules[0].moduleType === "content" && modules[0].style === "1") ||
        (modules[0].moduleType === "content" && modules[0].style === "2");
    return (
        <section className={$.container}>
            <div className={$.containerInner}>
                {pageTitle && !showPageTitle && (
                    <ModuleWrapper>
                        <Container>
                            <h1 className={$.title} style={{ marginBottom: 0 }}>
                                {pageTitle}
                            </h1>
                        </Container>
                    </ModuleWrapper>
                )}
                {modules.map((module: Module, index: number) => (
                    <ModuleWrapper
                        key={index}
                        moduleType={module.moduleType}
                        styleType={
                            module.moduleType === "content"
                                ? module.style
                                : undefined
                        }
                    >
                        {renderModule(module, index)}
                    </ModuleWrapper>
                ))}
            </div>
            <div className={$.containerBackgroundImage} />
        </section>
    );
};

export default ModuleHandler;
