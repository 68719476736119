import axios from "axios";

export default async function fetchCampaignTypes(
    apiUrl: string,
    subscriptionKey?: string,
) {
    const url = `${apiUrl}/contacts/campaigntypes`;
    const campaignTypes = await axios(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey ?? "",
        },
    });
    return campaignTypes;
}
