import * as React from "react";
import $ from "./BackgroundVideo.module.scss";

interface Props {
    videoUrl?: string;
}

export default function BackgroundVideo(props: Props) {
    const { videoUrl } = props;

    return (
        <video autoPlay muted loop className={$.video}>
            <source src={videoUrl} />
        </video>
    );
}
