const endpoints = {
    base: "/api/v1",
    init: "/init",
    page(slug: string): string {
        return `/pages/${slug}`;
    },
    category(slug: string): string {
        return `/product-categories/${slug}`;
    },
    product(slug: string): string {
        return `/products/${slug}`;
    },
};

export default endpoints;
