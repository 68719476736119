import * as React from "react";
import classnames from "classnames";
import $ from "./Container.module.scss";

type Theme = "slim";

interface Props {
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    as?: any;
    fullWidth?: boolean;
    theme?: Theme;
    [prop: string]: any;
}

const Container: React.SFC<Props> = ({
    children,
    className,
    fullWidth,
    theme,
    as: ElementType,
    ...rest
}: Props) => {
    const containerClasses = classnames(
        $.container,
        className,
        fullWidth ? $.isFull : null,
        theme === "slim" ? $.isSlim : null,
    );
    return (
        <ElementType className={containerClasses} {...rest}>
            {children}
        </ElementType>
    );
};

Container.defaultProps = {
    as: "div",
};

export default Container;
