import * as React from "react";
import { Button } from "view/components";
import $ from "./FormResponse.module.scss";

interface Props {
    title: string;
    text: string;
    link?: string;
    buttonText?: string;
    onClickFunction?: () => void;
}

const FormResponse = (props: Props) => {
    const { title, text, link, buttonText, onClickFunction } = props;

    return (
        <div className={$.base}>
            <h2 className={$.title}>{title}</h2>
            <p className={$.text}>{text}</p>
            {(link || onClickFunction) && buttonText && (
                <Button
                    text={buttonText}
                    navy
                    link={link}
                    onClick={onClickFunction}
                />
            )}
        </div>
    );
};

export default FormResponse;
