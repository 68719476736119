import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ConfigContextType } from "view/context/ConfigContext";

interface FetchUrlParams {
    config: ConfigContextType;
    endpoint: string;
    preview?: string;
    method?: "GET" | "POST" | "PUT" | "DELETE";
    body?: any;
    params?: any;
}

export default async function fetchUrl<Entity>({
    body,
    config,
    endpoint,
    preview,
    method = "GET",
}: FetchUrlParams): Promise<Entity> {
    const { apiUrl, portalKey } = config;
    const headers: AxiosRequestHeaders = {
        "X-Portal-Key": portalKey,
        "Ocp-Apim-Subscription-Key": "",
    };
    const options: AxiosRequestConfig = {
        method,
        headers,
        data: body ? JSON.stringify(body) : undefined,
    };
    const isPreview = preview !== "" && preview !== undefined;
    const url = `${apiUrl}${endpoint}${isPreview ? `?preview=${preview}` : ""}`;
    const response = axios(url, options)
        .then((res) => {
            return res.data as Entity;
        })
        .catch((error) => {
            if (error.response) {
                return error.response;
            }
            return error;
        });

    return response;
}
