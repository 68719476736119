import { createContext } from "react";
import * as Flickity from "flickity";

type ContextType = {
    setFlickityInstance(instance: Flickity): void;
    instance: Flickity | null;
} | null;

const FlickityCarouselContext = createContext<ContextType>(null);

export default FlickityCarouselContext;
