import * as React from "react";
import * as Flickity from "flickity";
import FlickityCarouselContext from "./FlickityCarousel.Context";
import FlickityCarouselWrapper from "./FlickityCarousel.Wrapper";
import FlickityCarouselNavigationButton from "./FlickityCarousel.NavigationButton";

type Props = {
    className?: string;
};

type ComponentType = React.FunctionComponent<Props> & {
    Wrapper: typeof FlickityCarouselWrapper;
    NavigationButton: typeof FlickityCarouselNavigationButton;
};

const FlickityCarousel: ComponentType = ({ children, className }) => {
    const [flickity, setFlickity] = React.useState<Flickity | null>(null);
    const setFlickityInstance = React.useCallback((instance: Flickity) => {
        setFlickity(instance);
    }, []);
    const flickityContext: React.ContextType<typeof FlickityCarouselContext> =
        React.useMemo(
            () => ({
                setFlickityInstance,
                instance: flickity,
            }),
            [setFlickityInstance, flickity],
        );

    return (
        <FlickityCarouselContext.Provider value={flickityContext}>
            <div style={{ overflow: "hidden" }} className={className}>
                {children}
            </div>
        </FlickityCarouselContext.Provider>
    );
};

FlickityCarousel.Wrapper = FlickityCarouselWrapper;
FlickityCarousel.NavigationButton = FlickityCarouselNavigationButton;

export default FlickityCarousel;
