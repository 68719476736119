/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

export default async function sendForm(
    apiUrl: string,
    subscriptionKey: string | undefined,
    body: any,
    contactType?: string,
) {
    const url = `${apiUrl}/contacts/contactform${
        contactType ? `?contactType=${contactType}` : ""
    }`;
    const response = await axios(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey ?? "",
        },
        data: body,
    });
    if (response.status === 201) {
        return response.data;
    }
    throw new Error();
}
