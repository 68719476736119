import { Params, Init } from "core/models/Init";
import endpoints from "core/constants/endpoints";
import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";

export default async function fetchInit(config: ConfigContextType) {
    const endpoint = endpoints.init;
    const result = await fetchUrl<Params>({ config, endpoint });
    return new Init({
        data: result.data,
    });
}
