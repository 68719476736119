import * as React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Logo, Hamburger, Container } from "view/components";
import { MenuItem, ImageVideoObject } from "core/models";
import AppContext from "view/context/AppContext";
import useWindowWidth from "view/hooks/useWindowWidth";
import { breakpoints } from "core/constants";
import $ from "./Menu.module.scss";

const Menu: React.FunctionComponent = () => {
    const [active, setActive] = React.useState(false);
    const toggleMenu = React.useCallback(() => {
        setActive((prevActive) => !prevActive);
    }, []);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.mobile;
    const appContext = React.useContext(AppContext);
    if (!appContext) return null;

    let title: string | null = null;
    let logoMenu: ImageVideoObject | null = null;
    let logoMenuSize: string | null = null;
    let menuItems: MenuItem[] = [];
    let mainMenuStyle: string | null = null;
    let navigationInCaps: boolean | null = null;
    let menuColorBackground: string | null = null;
    let menuColorText: string | null = null;
    let menuBoxShadow: string | null = null;
    let menuColorTextActive: string | null = null;
    const { showHeaderLogo, init } = appContext;
    if (init) {
        const { configuration, mainMenu } = init;
        menuItems = mainMenu;
        if (configuration) {
            ({
                title,
                logoMenu,
                logoMenuSize,
                mainMenuStyle,
                navigationInCaps,
                menuColorBackground,
                menuColorText,
                menuBoxShadow,
                menuColorTextActive,
            } = configuration);
        }
    }
    const isCenter = mainMenuStyle === "align-center";
    const isLogoLarge = logoMenuSize === "large";

    const menuClassnames = classnames([
        $.menu,
        isLogoLarge && $.menuLarger,
        isCenter && $.menuCenter,
        menuBoxShadow && $.menuShadow,
    ]);
    const menuStyle = menuColorBackground
        ? {
              backgroundColor: menuColorBackground,
          }
        : undefined;
    const menuPlaceholderClassnames = classnames(
        $.menuPlaceholder,
        isLogoLarge && $.menuPlaceholderLarger,
        isCenter && $.menuPlaceholderCenter,
    );
    const navigationClassnames = classnames(
        $.navigation,
        isLogoLarge && $.navigationLarger,
        isCenter && $.navigationCenter,
        active && $.isActive,
        navigationInCaps && $.hasCaps,
    );
    const navigationStyle: React.CSSProperties | undefined = menuColorText
        ? {
              color: menuColorText,
              backgroundColor:
                  isMobile && menuColorBackground
                      ? menuColorBackground
                      : "transparent",
          }
        : undefined;
    const navLinkStyle = menuColorTextActive
        ? {
              color: menuColorTextActive,
          }
        : undefined;

    const renderNavLink = (item: MenuItem, index: number) => {
        return (
            <li key={index} className={$.listItem}>
                <NavLink
                    exact
                    to={item.path || item.externalUrl}
                    onClick={() => setActive(false)}
                    className={$.link}
                    activeClassName={$.linkActive}
                    activeStyle={navLinkStyle}
                >
                    {item.title}
                </NavLink>
            </li>
        );
    };

    const renderCenteredNav = () => {
        const menuItemsLeft =
            menuItems && menuItems.slice(0, menuItems.length / 2);
        const menuItemsRight =
            menuItems &&
            menuItems.slice(menuItems.length / 2, menuItems.length);
        const logoCenteredStyle = {
            backgroundImage: logoMenu ? `url(${logoMenu.url})` : undefined,
            opacity: showHeaderLogo ? "1" : "0",
        };

        return (
            <div className={navigationClassnames} style={navigationStyle}>
                <Container>
                    <ul id="navigation" className={$.navList}>
                        <div
                            className={classnames(
                                $.navListPart,
                                $.navListPartLeft,
                            )}
                        >
                            {menuItemsLeft.map(
                                (item: MenuItem, index: number) =>
                                    renderNavLink(item, index),
                            )}
                        </div>
                        <div
                            className={classnames(
                                $.navListLogoSpace,
                                isLogoLarge && $.navListLogoSpaceLarge,
                            )}
                        >
                            <li className={$.listItem}>
                                <NavLink
                                    to="/"
                                    className={classnames(
                                        $.logoWrap,
                                        isLogoLarge && $.logoWrapLarge,
                                    )}
                                    style={logoCenteredStyle}
                                />
                            </li>
                        </div>
                        <div
                            className={classnames(
                                $.navListPart,
                                $.navListPartRight,
                            )}
                        >
                            {menuItemsRight.map(
                                (item: MenuItem, index: number) =>
                                    renderNavLink(item, index),
                            )}
                        </div>
                    </ul>
                </Container>
            </div>
        );
    };

    const renderDefaultNav = () => {
        return (
            <div className={navigationClassnames} style={navigationStyle}>
                <Container>
                    <ul id="navigation" className={$.navList}>
                        {menuItems.map((item: MenuItem, index: number) =>
                            renderNavLink(item, index),
                        )}
                    </ul>
                </Container>
            </div>
        );
    };

    return (
        <>
            <header className={menuClassnames} style={menuStyle}>
                <Container className={$.container}>
                    {logoMenu && (!isCenter || (isCenter && isMobile)) && (
                        <Logo
                            src={logoMenu.url}
                            alt={title}
                            className={classnames(
                                $.logo,
                                isLogoLarge && $.logoLarge,
                                showHeaderLogo ? $.logoVisible : null,
                            )}
                        />
                    )}
                    <Hamburger
                        onClick={toggleMenu}
                        className={$.hamburger}
                        active={active}
                    />
                </Container>
            </header>

            <div className={menuPlaceholderClassnames} />

            {isCenter && menuItems && renderCenteredNav()}
            {!isCenter && menuItems && renderDefaultNav()}
        </>
    );
};

export default Menu;
