import * as React from "react";
import { Init, FontFamily, Font } from "core/models";

export default function useFonts(init: Init) {
    const loadFont = (name: string, source: string) => {
        if (!source) return null;

        const css = `
            @font-face {
                font-family: '__${name}';
                src: ${source};
                font-display: auto;
            }
        `;
        const head = document.head || document.getElementsByTagName("head")[0];
        const style = document.createElement("style");

        head.appendChild(style);
        style.type = "text/css";
        style.appendChild(document.createTextNode(css));
    };

    const getFontSource = (font: Font, isLastItem: boolean): string => {
        if (font.type === "external") return "";
        const comma = !isLastItem ? ", " : "";
        return `url('${font.url}')${comma}`;
    };

    const loadFonts = (fonts: FontFamily[]) => {
        if (!fonts) return null;
        fonts.map((fontFamily: FontFamily) => {
            let source = "";
            const { key, uriList } = fontFamily;
            const { length } = uriList;
            uriList.map((font: Font, index) => {
                source += getFontSource(font, length === index + 1);
            });
            loadFont(key, source);
        });
    };

    React.useEffect(() => {
        if (init && init.configuration && init.configuration.fonts) {
            const {
                configuration: { fonts },
            } = init;
            loadFonts(fonts);
        }

        () => {
            // remove
        };
    }, [init]);
}
