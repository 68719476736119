import { CateogoryParams, Category } from "core/models/Category";
import endpoints from "core/constants/endpoints";
import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";

type CategoryType = {
    data: CateogoryParams[] | null;
};

export default async function fetchCategory(
    config: ConfigContextType,
    slug: string,
    preview: string,
) {
    const endpoint = endpoints.category(slug);
    const result = await fetchUrl<CategoryType>({ config, endpoint, preview });

    if (!result || !result.data || !result.data.length) return null;

    const data: CateogoryParams = result.data[0];

    return new Category(data);
}
