import { PageParams, Page } from "core/models/Page";
import endpoints from "core/constants/endpoints";
import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";

type PageType = {
    data: PageParams[] | null;
    status?: number;
};

export default async function fetchPage(
    config: ConfigContextType,
    slug: string,
    preview: string,
) {
    const endpoint = endpoints.page(slug);
    const result = await fetchUrl<PageType>({ config, endpoint, preview });

    if (!result || !result.data || !result.data.length) return null;

    const data: PageParams = result.data[0];

    return new Page(data);
}
