import * as React from "react";
import classnames from "classnames";
import $ from "./ModuleWrapper.module.scss";

interface Props {
    children: React.ReactNode;
    moduleType?: string;
    styleType?: string;
}

const Wrapper = ({ children, moduleType, styleType }: Props) => {
    const contentFullWidth =
        moduleType === "content" && (styleType === "1" || styleType === "2");
    const style =
        moduleType === "productSlider" ||
        moduleType === "video" ||
        contentFullWidth
            ? {
                  paddingTop: 0,
              }
            : {};

    return (
        <div
            className={classnames(
                $.module,
                contentFullWidth ? $.moduleMargin : null,
            )}
            style={style}
        >
            {children}
        </div>
    );
};

export default Wrapper;
