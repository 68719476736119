import * as React from "react";
import Color from "color";
import cssVars from "css-vars-ponyfill";
import { Init } from "core/models";

const convertCssImageUrl = (path: string) => `url("${path}")`;

export default function useColors(init: Init) {
    React.useEffect(() => {
        let primaryColorBackground: string | null = null;
        let primaryColorFont: string | null = null;
        let footerColor: string | null = null;
        let backgroundImageUrl: string | null = null;
        let backgroundImageMobileUrl: string | null = null;

        if (init && init.configuration) {
            const { configuration } = init;
            primaryColorBackground = configuration.primaryColorBackground;
            primaryColorFont = configuration.primaryColorFont;
            footerColor = configuration.footerColor;
            if (
                init.configuration.backgroundImage &&
                init.configuration.backgroundImage.url
            ) {
                backgroundImageUrl = convertCssImageUrl(
                    init.configuration.backgroundImage.url,
                );
            }
            if (
                init.configuration.backgroundImageMobile &&
                init.configuration.backgroundImageMobile.url
            ) {
                backgroundImageMobileUrl = convertCssImageUrl(
                    init.configuration.backgroundImageMobile.url,
                );
            }
        }
        const backgroundColorDarker =
            primaryColorBackground &&
            Color(primaryColorBackground).darken(0.35).toString();
        const backgroundColorLighter =
            primaryColorBackground &&
            Color(primaryColorBackground).alpha(0.9).toString();
        const secondaryColorBackground =
            primaryColorBackground === "#000000"
                ? backgroundColorLighter
                : backgroundColorDarker;
        const variables: { [key: string]: string } = {};

        if (primaryColorBackground) {
            variables["--primary-color-background"] = primaryColorBackground;
        }
        if (secondaryColorBackground) {
            variables["--secondary-color-background"] =
                secondaryColorBackground;
        }
        if (primaryColorFont) {
            variables["--primary-color-font"] = primaryColorFont;
        }
        if (footerColor) {
            variables["--footer-color-background"] = footerColor;
        }
        if (backgroundImageUrl) {
            variables["--background-image-url"] = backgroundImageUrl;
            // fallback mobile url when it does not exist
            variables["--background-image-mobile-url"] = backgroundImageUrl;
        }
        if (backgroundImageMobileUrl) {
            variables["--background-image-mobile-url"] =
                backgroundImageMobileUrl;
        }

        // use ponyfill to support IE11
        cssVars({
            variables,
            watch: true, // https://jhildenbiddle.github.io/css-vars-ponyfill/#/?id=watch
        });
    }, [init]);
}
