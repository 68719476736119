import * as React from "react";
import classnames from "classnames";
import ProgressiveImage from "react-progressive-graceful-image";
import $ from "./Image.module.scss";

interface Props {
    src: string;
    placeholder?: string;
    background?: boolean;
    alt?: string;
    style?: React.CSSProperties;
    className?: string;
}

const Image = (props: Props) => {
    const { placeholder, src, background, alt, style, className } = props;
    const blur = 65;

    return (
        <ProgressiveImage placeholder={placeholder || ""} src={src}>
            {(image: string, loading) => {
                if (background) {
                    return (
                        <div
                            title={alt}
                            className={classnames($.backgroundImage, className)}
                            style={{
                                ...style,
                                backgroundImage: `url("${image}")`,
                                filter: loading ? `blur(${blur}px)` : "none",
                            }}
                            data-testid="backgroundimage"
                        >
                            {placeholder && (
                                <noscript>
                                    <img
                                        src={image}
                                        alt={alt}
                                        data-testid="noscriptimage"
                                    />
                                </noscript>
                            )}
                        </div>
                    );
                }

                return (
                    <img
                        src={image}
                        className={className}
                        alt={alt}
                        title={alt}
                        data-testid="image"
                        style={{
                            ...style,
                            filter: loading ? `blur(${blur}px)` : "none",
                        }}
                    />
                );
            }}
        </ProgressiveImage>
    );
};

export default Image;
