import * as React from "react";
import useResizeObserver from "view/hooks/useResizeObserver";
import CarouselContext from "./Carousel.Context";

type Props = {};

const CarouselItem: React.FunctionComponent<Props> = (props) => {
    const { children } = props;
    const context = React.useContext(CarouselContext);
    if (context === null) throw new Error("missing context for carousel!");
    const { currentIndex, childrenCount } = context;
    const containerRef = React.useRef<null | HTMLDivElement>(null);
    const containerEntry = useResizeObserver(containerRef);
    const containerWidth = containerEntry
        ? containerEntry.contentRect.width
        : 0;
    const wrapperStyles = {
        width: `${containerWidth * childrenCount}px`,
        display: "flex",
    };

    return (
        <div ref={containerRef}>
            <div style={wrapperStyles}>
                {React.Children.map(children, (child, index) => {
                    if (React.isValidElement<any>(child)) {
                        const selected = index === currentIndex;
                        const shouldMoveLast =
                            currentIndex === childrenCount - 1 && index === 0;
                        const shouldMoveFirst =
                            currentIndex === 0 && index === childrenCount - 1;
                        // eslint-disable-next-line no-nested-ternary
                        const containerOffset = shouldMoveLast
                            ? containerWidth
                            : shouldMoveFirst
                            ? childrenCount * containerWidth * -1
                            : currentIndex * containerWidth * -1;
                        return React.cloneElement(child, {
                            containerWidth,
                            containerOffset,
                            selected,
                        });
                    }

                    return null;
                })}
            </div>
        </div>
    );
};

export default CarouselItem;
