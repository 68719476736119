import * as React from "react";

type State = {
    hasError: boolean;
};

export default class ErrorCatcher extends React.Component<{}, State> {
    state: State = {
        hasError: false,
    };

    componentDidCatch(error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ hasError: true });
    }

    resetError = () => this.setState({ hasError: false });

    render() {
        if (this.state.hasError) {
            return (
                <button type="button" onClick={this.resetError}>
                    error happened, reload
                </button>
            );
        }

        return this.props.children;
    }
}
