import * as React from "react";
import classnames from "classnames";
import { FormFields, Configuration } from "core/models";
import { FormSelect } from "view/components";
import AppContext from "view/context/AppContext";
import $ from "./FormFieldset.module.scss";

interface Props {
    title?: string;
    fields: FormFields[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChange: (e: any, name: string, files?: any) => void;
    hideTitle?: boolean;
}

const FormFieldset = (props: Props) => {
    const appContext = React.useContext(AppContext);
    let ctaInCaps: null | Configuration["ctaInCaps"] = null;
    let ctaLayout: null | Configuration["ctaLayout"] = null;
    let ctaFont: null | Configuration["ctaFont"] = null;

    if (appContext && appContext.init && appContext.init.configuration) {
        const {
            init: { configuration },
        } = appContext;
        if (configuration) {
            ({ ctaInCaps, ctaLayout, ctaFont } = configuration);
        }
    }

    const { form, title, hideTitle, fields, handleChange } = props;
    const [selectedFiles, setSelectedFiles] = React.useState<number>(0);

    const getPatternForType = (type: string) => {
        switch (type) {
            case "email":
                return "[^@\\s]+@[^@\\s]+\\.[^@\\s]+";
            case "tel":
                // 31612345678 or 0612345678
                return "\\d{10,11}";
            case "postcode":
                // 1234AB or 1234 AB
                return "\\d{4}\\s?[A-Z]{2}";
            case "date":
                // 01-01-2020
                return "\\d{1,2}\\-\\d{1,2}\\-\\d{4}";
            default:
                return undefined;
        }
    };

    const getMaxAllowedDate = () => {
        return new Date().toISOString().split("T")[0];
    };

    return (
        <fieldset className={$.base}>
            {!hideTitle && title && <h3 className={$.title}>{title}</h3>}
            {fields.map((field) => {
                const {
                    type,
                    options,
                    name,
                    required,
                    placeholder,
                    labelText,
                } = field;

                if (type === "select" && options) {
                    return (
                        <FormSelect
                            value={form[name]}
                            handleChange={handleChange}
                            {...field}
                        />
                    );
                }
                if (type === "radio" && options) {
                    return (
                        <div key={name} className={$.radioWrap}>
                            <p className={$.radioTitle}>
                                {placeholder}
                                {required && "*"}
                            </p>
                            {options.map((option) => (
                                <label
                                    htmlFor={option.value}
                                    key={option.value}
                                    className={$.radioLabelWrap}
                                >
                                    <input
                                        type={type}
                                        name={name}
                                        value={option.value}
                                        required={required}
                                        id={option.value}
                                        onChange={(e) => handleChange(e, name)}
                                        checked={form[name] === option.value}
                                        className={$.radioBox}
                                    />
                                    <span className={$.radioCustomBox} />
                                    <span className={$.radioLabel}>
                                        {option.label}
                                    </span>
                                </label>
                            ))}
                        </div>
                    );
                }
                if (type === "textarea") {
                    return (
                        <>
                            <label htmlFor={name}>
                                {placeholder}
                                {required && "*"}
                            </label>
                            <textarea
                                key={name}
                                aria-label={placeholder}
                                placeholder={`${placeholder}${
                                    required ? "*" : ""
                                }`}
                                required={required}
                                rows={8}
                                className={classnames([$.input, $.textArea])}
                                value={form[name]}
                                onChange={(e) => handleChange(e, name)}
                            />
                        </>
                    );
                }
                if (type === "date") {
                    return (
                        <>
                            <label htmlFor={name}>
                                {labelText || placeholder}
                                {required && "*"}
                            </label>
                            <input
                                key={name}
                                id={name}
                                type={type}
                                aria-label={placeholder}
                                placeholder="dd-mm-jjjj*"
                                required={required}
                                pattern={getPatternForType(type)}
                                className={classnames([$.input, $.textDate])}
                                value={form[name]}
                                onChange={(e) => {
                                    const element = document.getElementById(
                                        name,
                                    ) as HTMLInputElement;
                                    if (element) {
                                        if (element.value === "") {
                                            element.style.color =
                                                getComputedStyle(
                                                    document.documentElement,
                                                ).getPropertyValue(
                                                    "--placeholder-color-font",
                                                );
                                        } else {
                                            element.style.color =
                                                getComputedStyle(
                                                    document.documentElement,
                                                ).getPropertyValue(
                                                    "--primary-color-font",
                                                );
                                        }
                                    }
                                    handleChange(e, name);
                                }}
                                max={getMaxAllowedDate()}
                            />
                        </>
                    );
                }
                if (type === "file") {
                    const labelStyle = classnames([
                        $.fileLabel,
                        ctaInCaps && $.fileLabelCaps,
                        ctaLayout === "square-rounded" &&
                            $.fileLabelIsSquareRounded,
                        ctaFont === "primary" && $.fileLabelPrimaryFont,
                        ctaFont === "secondary" && $.fileLabelSecondaryFont,
                    ]);
                    return (
                        <>
                            <input
                                accept="image/*"
                                id={name}
                                key={name}
                                type={type}
                                aria-label={placeholder}
                                placeholder={`${placeholder}${
                                    required ? "*" : ""
                                }`}
                                required={required}
                                className={classnames([$.input, $.file])}
                                onChange={(e) => {
                                    setSelectedFiles(
                                        e.target.files
                                            ? e.target.files.length
                                            : 0,
                                    );
                                    handleChange(e, name, e.target.files);
                                }}
                                multiple
                            />
                            <label htmlFor={name} className={labelStyle}>
                                Bijlage(s) toevoegen
                            </label>
                            {selectedFiles > 0 && (
                                <p className={$.fileCount}>
                                    {selectedFiles} bestand
                                    {selectedFiles > 1 ? "en" : ""} toegevoegd
                                </p>
                            )}
                        </>
                    );
                }
                return (
                    <>
                        <label htmlFor={name}>
                            {labelText || placeholder}
                            {required && "*"}
                        </label>
                        <input
                            key={name}
                            type={type}
                            aria-label={placeholder}
                            placeholder={`${placeholder}${required ? "*" : ""}`}
                            required={required}
                            className={classnames([$.input, $.textInput])}
                            value={form[name]}
                            minLength={type === "tel" ? 10 : 1}
                            onChange={(e) => handleChange(e, name)}
                            pattern={getPatternForType(type)}
                        />
                    </>
                );
            })}
            <span className={$.requiredLabel}>
                Alle velden met een * zijn verplicht.
            </span>
        </fieldset>
    );
};

export default FormFieldset;
