/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";
import { Link } from "react-router-dom";
import isExternal from "core/utils/isUrlExternal";
import Analytics from "core/services/analytics";

interface Props {
    to: string;
    children: Element | HTMLElement | React.ReactNode;
    className?: any;
    style?: any;
}

const LinkDuo = ({ to, ...props }: Props) => {
    const clickHandler = React.useCallback(() => {
        Analytics.event("link", "click", to);
    }, []);

    return isExternal(to) ? (
        <a href={to} target="_blank" rel="noopener noreferrer" {...props} />
    ) : (
        <Link to={to} {...props} onClick={clickHandler} />
    );
};

export default LinkDuo;
