import * as React from "react";
import { Faq as FaqProps } from "core/models/modules/FaqModule";
import { FaqItem, Container, Title } from "..";
import $ from "./Faq.module.scss";

const Faq = (props: FaqProps) => {
    const { title, faqReferences } = props;
    return (
        <Container>
            <section className={$.base} data-testid="section">
                <Title className={$.title} data-testid="title">
                    {title}
                </Title>
                {faqReferences && faqReferences.length && (
                    <div className={$.faqItems} data-testid="faq-items">
                        {faqReferences.map((item) => {
                            return <FaqItem {...item} key={item.question} />;
                        })}
                    </div>
                )}
                {(!faqReferences || faqReferences.length === 0) && (
                    <p data-testid="message">
                        Er zijn op dit moment geen veelgestelde vragen
                    </p>
                )}
            </section>
        </Container>
    );
};

export default Faq;
