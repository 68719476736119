/**
 * @description Tells you whether an URL is external or not.
 * Based on: https://github.com/mrded/is-url-external
 * @param url string
 */

export default function isUrlExternal(url: string): boolean {
    const host = window.location.hostname;

    // eslint-disable-next-line func-names
    const linkHost = (function (url) {
        if (/^https?:\/\//.test(url)) {
            // The easy way to parse an URL, is to create <a> element.
            // @see: https://gist.github.com/jlong/2428561
            const parser = document.createElement("a");
            parser.href = url;

            return parser.hostname;
        }
        // Relative URL.
        return window.location.hostname;
    })(url);

    return host !== linkHost;
}
