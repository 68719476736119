import * as React from "react";
import fetchInit from "core/services/api/init";
import { ConfigContextType } from "../context/ConfigContext";

export default function useInitService(config: ConfigContextType) {
    const [pending, setPending] = React.useState(false);
    const [init, setInit] = React.useState<any>(null);

    const fetchData = async () => {
        try {
            setPending(true);
            const response = await fetchInit(config);
            setInit(response);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        } finally {
            setPending(false);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return { init, pending };
}
