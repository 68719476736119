import { Module } from "./Module";
import { ImageVideoObject } from "./ImageVideoObject";

export interface CateogoryParams {
    title: string;
    slug: string;
    contentType: string;
    moduleReferences: Module[];
    path: string;
    seoDescription: string;
    text: string;
    image: ImageVideoObject;
}

export class Category {
    title: string;

    slug: string;

    contentType: string;

    moduleReferences: any[];

    path: string;

    seoDescription: string;

    text: string;

    image: ImageVideoObject;

    constructor(data: CateogoryParams) {
        const {
            title,
            slug,
            contentType,
            moduleReferences,
            path,
            seoDescription,
            text,
            image,
        } = data;
        this.title = title;
        this.slug = slug;
        this.contentType = contentType;
        this.moduleReferences = moduleReferences;
        this.path = path;
        this.seoDescription = seoDescription;
        this.text = text;
        this.image = image;
    }
}
