import * as React from "react";
import classnames from "classnames";
import { FormFields } from "core/models";
import $ from "./FormSelect.module.scss";

interface Props extends FormFields {
    title?: string;
    value: any;
    handleChange: (
        e: React.ChangeEvent<HTMLSelectElement>,
        name: string,
    ) => void;
}

export default function FormSelect({
    required,
    placeholder,
    handleChange,
    options,
    value,
    name,
    title,
}: Props) {
    return (
        <>
            {title && (
                <label htmlFor={name} className={$.title}>
                    {title}
                </label>
            )}
            <div className={$.selectWrap} key={name}>
                <select
                    id={name}
                    required={required}
                    aria-label={placeholder}
                    className={classnames([$.input, $.select])}
                    value={value || placeholder}
                    onChange={(e) => handleChange(e, e.target.value)}
                >
                    {options &&
                        options.map((option, index) => (
                            <option
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                value={option.value}
                                className={$.option}
                            >
                                {option.label}
                            </option>
                        ))}
                </select>
            </div>
        </>
    );
}
