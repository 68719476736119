import * as React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { ModuleWrapper, Container } from "..";
import $ from "./PageNotFound.module.scss";

type Props = {};

const PageNotFound: React.FunctionComponent<Props> = (props) => {
    return (
        <ModuleWrapper>
            <Helmet>
                <meta name="robots" content="NOINDEX, NOFOLLOW" />
                <meta name="prerender-status-code" content="404" />
            </Helmet>
            <Container className={$.container}>
                <h1>Pagina niet gevonden</h1>
                <p>
                    Helaas bestaat de pagina niet. Keer terug naar{" "}
                    <Link to="/">Home</Link> of probeer een van de links uit het
                    hoofdmenu.
                </p>
            </Container>
        </ModuleWrapper>
    );
};

export default PageNotFound;
