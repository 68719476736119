import axios from "axios";

export default async function fetchContactProducts(
    apiUrl: string,
    subscriptionKey: string | undefined,
    website: string,
) {
    if (website === "ranja") {
        // tslint:disable-next-line: no-parameter-reassignment
        website = "ranjadrinks";
    }
    const url = `${apiUrl}/contacts/products?website=${website}.nl`;
    const contactProducts = await axios(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey ?? "",
        },
    });
    return contactProducts;
}
