import * as React from "react";
import classnames from "classnames";
import { Content } from "core/models/modules/Content";
import Button from "view/components/Button";
import useWindowWidth from "view/hooks/useWindowWidth";
import Image from "view/components/Image";
import { breakpoints } from "core/constants";
import { Title, BackgroundVideo } from "view/components";
import $ from "./ContentFullWidth.module.scss";

const ContentFullWidth = (props: Content) => {
    const {
        style,
        image: imageDesktop,
        imageMobile,
        imageIcon,
        title,
        text,
        ctaLabel,
        ctaUrl,
        colorBackground,
        video,
    } = props;
    const titleStyles = classnames([
        $.title,
        style === "1" && $.titleA,
        style === "2" && $.titleB,
    ]);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.mobile;
    let image = imageDesktop;
    if (isMobile && imageMobile && imageMobile.url) {
        image = imageMobile;
    }
    const imgWidth = windowWidth;

    return (
        <section
            className={$.base}
            style={{ backgroundColor: colorBackground }}
        >
            {style === "2" && image && image.url && (
                <Image
                    placeholder={`${image.url}?w=30&q=50`}
                    src={`${image.url}?w=${imgWidth}&q=80`}
                    alt={image.title}
                    background
                />
            )}
            {style === "5" && video && video.url && (
                <BackgroundVideo videoUrl={video.url} />
            )}

            {(title || text) && <div className={$.overlay} />}
            <div className={$.content}>
                {style === "1" && imageIcon && (
                    <img
                        src={imageIcon.url}
                        alt={imageIcon.title}
                        title={imageIcon.title}
                        className={$.icon}
                    />
                )}
                {!!title && (
                    <Title weight="h1" className={titleStyles}>
                        {title}
                    </Title>
                )}
                {style === "1" || style === "2" && text && (
                    <div
                        className={$.text}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
                {ctaLabel && ctaUrl && (
                    <Button text={ctaLabel} link={ctaUrl} white />
                )}
            </div>
        </section>
    );
};

export default ContentFullWidth;
