import { ProductParams, Product } from "core/models/Product";
import endpoints from "core/constants/endpoints";
import fetchUrl from "core/services/fetchUrl";
import { ConfigContextType } from "view/context/ConfigContext";

type ProductType = {
    data: ProductParams[];
};

export default async function fetchProduct(
    config: ConfigContextType,
    slug: string,
    preview: string,
) {
    const endpoint = endpoints.product(slug);
    const result = await fetchUrl<ProductType>({ config, endpoint, preview });

    if (!result || !result.data || !result.data.length) return null;

    const data: ProductParams = result.data[0];

    return new Product(data);
}
