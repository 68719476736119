import { FormFields } from "core/models";

export function customerFields(): {
    title?: string;
    name?: string;
    fields: FormFields[];
}[] {
    return [
        {
            name: "general",
            fields: [
                {
                    type: "textarea",
                    name: "description",
                    placeholder: "Wat is je vraag, tip of opmerking?",
                    required: true,
                },
                {
                    type: "file",
                    name: "Bijlage(s)",
                    placeholder: "Bijlage(s) toevoegen",
                    required: false,
                },
            ],
        },
        {
            name: "specialOffer",
            fields: [
                {
                    type: "text",
                    name: "specialoffer",
                    placeholder: "Over welke actie heb je een vraag?",
                    required: true,
                },
                {
                    type: "textarea",
                    name: "description",
                    placeholder: "Wat is je vraag of opmerking?",
                    required: true,
                },
                {
                    type: "file",
                    name: "Bijlage(s)",
                    placeholder: "Bijlage(s) toevoegen",
                    required: false,
                },
            ],
        },
        {
            name: "product",
            fields: [
                {
                    type: "text",
                    name: "expirationdate",
                    placeholder: "Houdbaarheidsdatum",
                    required: false,
                },
                {
                    type: "text",
                    name: "productioncode",
                    placeholder:
                        "Productiecode (deze is te vinden bij de houdbaarheidsdatum)",
                    required: false,
                },
                {
                    type: "text",
                    name: "purchasespot",
                    placeholder: "Waar is het product gekocht?",
                    required: false,
                },
                {
                    type: "textarea",
                    name: "description",
                    placeholder: "Opmerking",
                    required: true,
                },
                {
                    type: "file",
                    name: "Bijlage(s)",
                    placeholder: "Bijlage(s) toevoegen",
                    required: false,
                },
                {
                    type: "radio",
                    name: "pickupproduct",
                    placeholder:
                        "Ik heb dit product en deze mag kosteloos bij mij worden opgehaald.",
                    required: true,
                    options: [
                        {
                            label: "Nee",
                            value: "no",
                        },
                        {
                            label: "Ja",
                            value: "yes",
                        },
                    ],
                },
            ],
        },
        {
            title: "Jouw gegevens",
            name: "Gegevens",
            fields: [
                {
                    type: "radio",
                    name: "gender",
                    placeholder: "Aanhef",
                    required: true,
                    options: [
                        {
                            label: "De heer",
                            value: "Man",
                        },
                        {
                            label: "Mevrouw",
                            value: "Vrouw",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "firstname",
                    placeholder: "Voornaam",
                    required: true,
                },
                {
                    type: "text",
                    name: "lastname",
                    placeholder: "Achternaam",
                    required: true,
                },
                {
                    type: "email",
                    name: "email",
                    placeholder: "E-mail",
                    required: true,
                },
                {
                    type: "tel",
                    name: "phone",
                    placeholder: "Telefoonnummer",
                    required: false,
                },
            ],
        },
        {
            name: "Gegevens lang",
            fields: [
                {
                    type: "text",
                    name: "street",
                    placeholder: "Straat",
                    required: true,
                },
                {
                    type: "text",
                    name: "housenumber",
                    placeholder: "Huisnummer",
                    required: true,
                },
                {
                    type: "text",
                    name: "housenumberaddition",
                    placeholder: "Toevoeging",
                    required: false,
                },
                {
                    type: "text",
                    name: "postalcode",
                    placeholder: "Postcode",
                    required: true,
                },
                {
                    type: "text",
                    name: "city",
                    placeholder: "Woonplaats",
                    required: true,
                },
                {
                    type: "text",
                    name: "country",
                    placeholder: "Land",
                    required: true,
                },
            ],
        },
    ];
}
