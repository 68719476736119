import * as React from "react";
import classnames from "classnames";

type Props = {
    className?: string;
    selectedClassName?: string;
    offset?: number;
    containerWidth?: number;
    containerOffset?: number;
    selected?: boolean;
};

const CarouselItem: React.FunctionComponent<Props> = (props) => {
    const {
        className,
        children,
        containerOffset,
        containerWidth,
        selected,
        selectedClassName,
    } = props;
    const itemRef = React.useRef<HTMLDivElement | null>(null);
    const itemClassNames = classnames(className, selected && selectedClassName);
    const itemStyles = {
        transform: containerOffset
            ? `translateX(${containerOffset}px)`
            : undefined,
        width: containerWidth,
        opacity: selected ? 1 : 0,
    };

    return (
        <div className={itemClassNames} style={itemStyles} ref={itemRef}>
            {children}
        </div>
    );
};

export default CarouselItem;
