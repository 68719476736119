import * as React from "react";
import { Content as ContentModel } from "core/models/modules/Content";
import ContentAnimated from "./ContentAnimated";
import ContentFullWidth from "./ContentFullWidth";
import ContentRow from "./ContentRow";

const Content = (props: ContentModel) => {
    if (props.style === "1") {
        return <ContentFullWidth {...props} />;
    }
    if (props.style === "2") {
        return <ContentFullWidth {...props} />;
    }
    if (props.style === "3") {
        return <ContentRow {...props} reversed={!!props.reversed} />;
    }
    if (props.style === "4") {
        return <ContentAnimated {...props} />;
    }
};

export default Content;
