import { Base } from "./modules/Base";

export interface Rewards extends Base {
    moduleType: "rewards";
    title: string;
    internalCampaignName: string;
    text?: string;
    campaignType: string;
    campaignStartDate: string;
    campaignEndDate?: string;
    campaignTermsLink: string;
    descriptionPlaceholderText?: string;
    contactServiceSubscriptionKey?: string;
    showDescriptionField?: boolean;
    showPhoneNumberField?: boolean;
    postalCodeRequired?: boolean;
    showEanCodeField?: boolean;
    showAddressField?: boolean;
    eanCodeRequired?: boolean;
    descriptionRequired?: boolean;
    phoneNumberRequired?: boolean;
    campaignNotStartedText?: string;
    campaignEndedText?: string;
    campaignSubmittedText?: string;
}

export interface RewardsFormFields {
    name: string;
    type: string;
    options?: RewardsFormOptions[];
    placeholder: string;
    required: boolean;
}

export interface RewardsFormOptions {
    value: string;
    label: string;
}

export interface CampaignTypeEntity {
    id: string;
    name: string;
    label: string;
}

export class CampaignType {
    id: string;

    name: string;

    label: string;

    constructor(campaignType: CampaignTypeEntity) {
        this.id = campaignType.id;
        this.name = campaignType.name;
        this.label = campaignType.label;
    }
}
