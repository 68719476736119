import * as React from "react";
import {
    Route,
    withRouter,
    RouteComponentProps,
    Switch,
    Redirect,
} from "react-router-dom";
import { Menu, Footer, Loader, Modal, HtmlCode } from "view/components";
import { Suspense } from "react";
import routes from "./routes";
import AppContext from "./context/AppContext";
import ConfigContext from "./context/ConfigContext";
import useHistory from "./hooks/useHistory";
import useInitService from "./hooks/useInitService";
import useColors from "./hooks/useColors";
import useFonts from "./hooks/useFonts";
import $ from "./App.module.scss";

type Props = RouteComponentProps;

const App: React.FunctionComponent<Props> = (props) => {
    const dontMatchMe = "{apiU";
    const placeholderValue = `#${dontMatchMe}rl}#`;
    if (window.APP_SETTINGS.apiUrl === placeholderValue) {
        throw new Error(
            `APP_SETTINGS.apiUrl contains placeholder value: '${placeholderValue}'`,
        );
    }

    const appSettings = window.APP_SETTINGS;
    const { pending, init } = useInitService(appSettings);
    useHistory(props);
    useColors(init);
    useFonts(init);
    const [showHeaderLogo, setShowHeaderLogo] = React.useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalInfo, setModalInfo] = React.useState<any>();
    const [pageData, setPageData] = React.useState<any>();
    const appContext: React.ContextType<typeof AppContext> = {
        modalOpen,
        setModalOpen,
        modalInfo,
        setModalInfo,
        showHeaderLogo,
        setShowHeaderLogo,
        init,
        pageData,
        setPageData,
    };

    let headerScript;
    let footerScript;
    if (init && init.html) {
        const { html } = init;
        if (html) {
            const { header, footer } = html;
            headerScript = header;
            footerScript = footer;
        }
    }

    const renderLoader = () => <Loader className="loaderFull" />;

    return (
        <AppContext.Provider value={appContext}>
            <ConfigContext.Provider value={appSettings}>
                {headerScript && <HtmlCode html={headerScript} />}
                <section className={$.container}>
                    {pending ? (
                        <Loader className="loaderFull" />
                    ) : (
                        <>
                            <Menu />

                            <Suspense fallback={renderLoader()}>
                                <Switch>
                                    {routes.map((route) => (
                                        <Route key={route.path} {...route} />
                                    ))}
                                    <Redirect to="/" />
                                </Switch>
                            </Suspense>

                            <Footer />

                            {appContext &&
                                appContext.modalOpen &&
                                appContext.modalInfo && (
                                    <Modal
                                        {...modalInfo}
                                        closeModal={() => setModalOpen(false)}
                                    />
                                )}
                        </>
                    )}
                </section>
                {footerScript && <HtmlCode html={footerScript} />}
            </ConfigContext.Provider>
        </AppContext.Provider>
    );
};

export default withRouter(App);
