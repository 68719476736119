import "intersection-observer";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import App from "view/App";
import "./styles/global.scss";
import ErrorCatcher from "./view/components/ErrorCatcher";

smoothscroll.polyfill();

const mountElement = document.getElementById("app");

ReactDOM.render(
    <ErrorCatcher>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ErrorCatcher>,
    mountElement,
);
