import axios from "axios";

export default async function sendContactAnnex({
    apiUrl,
    subscriptionKey,
    body,
    contactFormId,
}: any) {
    const formData = new FormData();
    body.forEach((file: string | Blob) => {
        formData.append("file", file);
    });

    const url = `${apiUrl}/contacts/contactform/${contactFormId}/attachments`;
    try {
        const response = await axios(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Ocp-Apim-Subscription-Key": subscriptionKey,
            },
            data: formData,
        });
        if (response) return response;
    } catch {
        return false;
    }
}
