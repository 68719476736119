import { createContext } from "react";

type ContextType = {
    childrenCount: number;
    currentIndex: number;
    navigatePrev(): void;
    navigateNext(): void;
};

const CarouselContext = createContext<ContextType | null>(null);

export default CarouselContext;
