/* tslint:disable */
/* eslint:disable */
import * as React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
    ProductCategorySlider as ProductCategorySliderType,
    ProductCategory,
    ProductSlider,
    ProductSlide,
    NutritionalValues,
    Ingredients,
    Table as TableModel,
    ProductStyle,
} from "core/models";
import AppContext from "view/context/AppContext";
import Analytics from "core/services/analytics";
import useWindowWidth from "view/hooks/useWindowWidth";
import { breakpoints } from "core/constants";
import arrowDown from "assets/icons/icon-arrowdown.svg";
import { Container, Button } from "..";
import $ from "./ProductCategorySlider.module.scss";
import FlickityCarousel from "../FlickityCarousel";
import Title from "../Title";

type SliderProps = ProductCategorySliderType | ProductSlider;
type ProductCategoryItemProps = {
    product: ProductCategory;
    isProduct: boolean;
};
type ProductItemProps = {
    headTitle?: string;
    product: ProductSlide;
    style: ProductStyle;
};
type ProductInformationProps = {
    ingredients: Ingredients;
    nutritionalValues?: NutritionalValues;
    analysis?: NutritionalValues;
    style: ProductStyle;
};
type ProductCategoryButtonProps = { to: "prev" | "next"; className?: string };
type TableProps = { title: string; table: TableModel[] };

const Table: React.FunctionComponent<TableProps> = (tableData) => {
    return (
        <section className={$.productInfoSection}>
            {tableData.title && tableData.table && (
                <>
                    <h4 className={$.productInfoTitle}>{tableData.title}</h4>
                    <ul className={$.productInfoList}>
                        {tableData.table.map((item) => {
                            return (
                                <li
                                    key={item.key}
                                    className={$.productInfoListItem}
                                >
                                    <span>{item.key}</span>
                                    <span>{item.value}</span>
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </section>
    );
};

const ProductInformation: React.FunctionComponent<ProductInformationProps> = ({
    ingredients,
    nutritionalValues,
    analysis,
    style,
}) => {
    const productInfoStyle = classnames([
        $.productInfo,
        style === "default-title-large" && $.productInfoDefault,
        style === "product-details-centered" && $.productInfoCentered,
        style === "product-details-left" && $.productInfoLeft,
    ]);

    return (
        <section className={productInfoStyle}>
            <section className={$.productInfoSection}>
                {ingredients.title && ingredients.text && (
                    <>
                        <h4 className={$.productInfoTitle}>
                            {ingredients.title}
                        </h4>
                        <p className={$.productInfoText}>{ingredients.text}</p>
                    </>
                )}
            </section>
            {nutritionalValues && <Table {...nutritionalValues} />}
            {analysis && <Table {...analysis} />}
        </section>
    );
};

const ProductCategoryButton: React.FunctionComponent<
    ProductCategoryButtonProps
> = (props) => {
    const { to, className } = props;

    return (
        <FlickityCarousel.NavigationButton
            className={classnames([
                $.button,
                className,
                to === "next" && $.isNext,
            ])}
            to={to}
        >
            <svg
                className={$.buttonIcon}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8.12 12"
            >
                <path d="M1.5,12c-0.38,0-0.77-0.15-1.06-0.44c-0.59-0.59-0.59-1.54,0-2.12L3.88,6L0.44,2.56c-0.59-0.59-0.59-1.54,0-2.12 c0.58-0.59,1.54-0.59,2.12,0L8.12,6l-5.56,5.56C2.27,11.85,1.88,12,1.5,12z" />
            </svg>
        </FlickityCarousel.NavigationButton>
    );
};

const ProductCategoryItem: React.FunctionComponent<ProductCategoryItemProps> = (
    props,
) => {
    const { product, isProduct } = props;
    const { image } = product;
    const appContext = React.useContext(AppContext);
    let ctaColor;
    if (appContext && appContext.init && appContext.init.configuration) {
        const {
            init: { configuration },
        } = appContext;
        ctaColor = configuration.ctaColor;
    }
    const buttonStyle = {
        backgroundColor:
            ctaColor ||
            (product && product.color ? product.color.background : "#00A6D6"),
        // color: ctaColor ? '#ffffff' : (product && product.color ? product.color.font : '#ffffff'),
        color: "#ffffff", // always white in order of Marloes
        marginTop: "1rem",
    };

    if (!appContext) throw new Error("missing appContext in render root");
    const {
        init: { productPaths, categoryPaths },
    } = appContext;
    const productNavItem =
        isProduct &&
        productPaths &&
        productPaths.find((navItem) => navItem.slug === product.slug);
    const categoryNavItem =
        !isProduct &&
        categoryPaths &&
        categoryPaths.find((navItem) => navItem.slug === product.slug);
    let to = "#";
    if (productNavItem && productNavItem.path) {
        to = productNavItem.path;
    }
    if (categoryNavItem && categoryNavItem.path) {
        to = categoryNavItem.path;
    }
    const clickHandler = React.useCallback(() => {
        Analytics.event("product slider button", "click", to);
    }, []);
    const imageUrl = `${image.url}?w=600&q=80`;

    return (
        <section className={$.item}>
            <Link
                className={$.itemImageContainer}
                to={to}
                onClick={clickHandler}
            >
                <span
                    className={$.itemImage}
                    style={{ backgroundImage: `url("${imageUrl}")` }}
                >
                    <img src={imageUrl} alt={image.title} />
                </span>
            </Link>
            <Button
                text={product.title}
                onClick={clickHandler}
                link={to}
                style={buttonStyle}
                navy
            />
        </section>
    );
};

const ProductItem: React.FunctionComponent<ProductItemProps> = (props) => {
    const { product, headTitle, style } = props;
    const appContext = React.useContext(AppContext);
    if (!appContext) return null;
    const { init, setModalOpen, setModalInfo } = appContext;
    const { mainMenuStyle, logoMenuSize } = init.configuration;
    const isCenter = mainMenuStyle === "align-center";
    const isLarge = logoMenuSize === "large";
    const extraPaddingTop = isCenter && isLarge;

    const itemStyles = classnames([
        $.item,
        $.itemDetail,
        style === "default-title-large" && $.itemDetailTitleLarge,
    ]);
    const imageStyles = classnames([$.itemImage, $.itemImageDetail]);
    const titleStyles = classnames([$.title, $.titleDetail]);
    const openModal = () => {
        setModalOpen(true);
        setModalInfo(product.shopAvailability);
    };

    if (!appContext) throw new Error("missing appContext in render root");

    const contentHTML = () => {
        return (
            <>
                {!!headTitle && (
                    <Title className={titleStyles}>{headTitle}</Title>
                )}
                {!!product.title && (
                    <Title weight="h1" className={$.itemTitle}>
                        {product.title}
                    </Title>
                )}
                {!!product.text && (
                    <div
                        className={$.itemText}
                        dangerouslySetInnerHTML={{ __html: product.text }}
                        style={{ color: product.color.font }}
                    />
                )}
                {!!product.ingredients &&
                    (product.ingredients.title || product.ingredients.text) && (
                        <ProductInformation
                            ingredients={product.ingredients}
                            nutritionalValues={product.nutritionalValues}
                            analysis={product.analysis}
                            style={style}
                        />
                    )}
                {!!product.ctaLabel && (
                    <Button
                        className={$.itemDetailButton}
                        text={product.ctaLabel}
                        onClick={openModal}
                        navy
                    />
                )}
            </>
        );
    };

    const image = product.imageDetail || product.image;
    const imageUrl = `${image.url}?w=1200&h=1200&q=80`;

    switch (style) {
        case "product-details-centered":
        case "product-details-left":
            const isCentered = style === "product-details-centered";
            return (
                <section
                    className={classnames(
                        $.itemDetailsLeft,
                        isCentered && $.itemDetailsLeftWider,
                    )}
                >
                    <div className={$.flex}>
                        <div
                            className={$.detailsLeft}
                            style={{
                                backgroundColor: product.color.background,
                            }}
                        >
                            <div className={$.content}>
                                <Container>
                                    <div className={$.contentContainer}>
                                        {contentHTML()}
                                    </div>
                                </Container>
                            </div>
                        </div>
                        <div className={$.detailsRight}>
                            {!!image.url && (
                                <div
                                    className={imageStyles}
                                    style={{
                                        backgroundImage: `url("${imageUrl}")`,
                                    }}
                                >
                                    <img src={imageUrl} alt={image.title} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={$.content}>
                        <Container>
                            <div className={$.contentContainer}>
                                {contentHTML()}
                            </div>
                        </Container>
                    </div>
                </section>
            );
        case "default-title-large":
        case "default":
        default:
            return (
                <section className={itemStyles}>
                    <div
                        className={classnames([
                            $.itemTitles,
                            extraPaddingTop && $.itemTitlesExtraPadding,
                        ])}
                        style={{ backgroundColor: product.color.background }}
                    >
                        {!!headTitle && (
                            <Title className={titleStyles}>{headTitle}</Title>
                        )}
                        {!!product.title && (
                            <Title weight="h1" className={$.itemTitle}>
                                {product.title}
                            </Title>
                        )}
                    </div>
                    <div className={$.itemLeftColumn}>
                        {!!image.url && (
                            <div
                                className={imageStyles}
                                style={{
                                    backgroundImage: `url("${imageUrl}")`,
                                }}
                            >
                                <img src={imageUrl} alt={image.title} />
                            </div>
                        )}
                        {!!product.text && (
                            <div
                                className={$.itemText}
                                dangerouslySetInnerHTML={{
                                    __html: product.text,
                                }}
                                style={{ color: product.color.font }}
                            />
                        )}
                    </div>
                    <img
                        src={arrowDown}
                        alt="Lees meer beneden"
                        role="presentation"
                        className={$.itemArrow}
                    />
                    <div className={$.itemRightColumn}>
                        {!!product.ingredients &&
                            (product.ingredients.title ||
                                product.ingredients.text) && (
                                <ProductInformation
                                    ingredients={product.ingredients}
                                    nutritionalValues={
                                        product.nutritionalValues
                                    }
                                    analysis={product.analysis}
                                    style={style}
                                />
                            )}
                        {!!product.ctaLabel && (
                            <Button
                                text={product.ctaLabel}
                                onClick={openModal}
                                navy
                            />
                        )}
                    </div>
                </section>
            );
    }
};

const ProductCategorySlider: React.FunctionComponent<SliderProps> = (props) => {
    const { title, id, moduleType, style, productCategories, products } = props;
    const isProductSliderButActuallyCategorySlider = style === "2";
    const isCategory = moduleType === "productCategorySlider";
    const isCategorySlider =
        isCategory || isProductSliderButActuallyCategorySlider;
    const carouselItems = isCategory ? productCategories : products;
    const items = React.useMemo(() => carouselItems, [id]);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < breakpoints.desktop;
    const containerStyles = classnames([
        $.container,
        !isProductSliderButActuallyCategorySlider &&
            moduleType === "productSlider" &&
            $.containerDetail,
    ]);

    return (
        <div className={containerStyles}>
            {(moduleType === "productCategorySlider" ||
                isProductSliderButActuallyCategorySlider) &&
                title && (
                    <Title weight="h1" className={$.title}>
                        {title}
                    </Title>
                )}
            <FlickityCarousel className={$.carousel}>
                {(() => {
                    if (isCategorySlider) {
                        const mobileLength = 1;
                        const desktopLength = 3;
                        const length = isMobile ? mobileLength : desktopLength;
                        const showButtons =
                            carouselItems && carouselItems.length > length;
                        return (
                            <>
                                {showButtons && (
                                    <ProductCategoryButton
                                        className={classnames($.carouselButton)}
                                        to="prev"
                                    />
                                )}
                                {showButtons && (
                                    <ProductCategoryButton
                                        className={classnames(
                                            $.carouselButton,
                                            $.isNext,
                                        )}
                                        to="next"
                                    />
                                )}
                                {items && (
                                    <Container>
                                        <FlickityCarousel.Wrapper
                                            className={$.carouselWrapper}
                                            isCategorySlider={isCategorySlider}
                                            items={items}
                                            scrollable={showButtons}
                                            // @TODO: Properly cast the item type as currently it is Unknown
                                            renderItem={({
                                                item,
                                                index,
                                            }: {
                                                item: ProductCategory;
                                                index: number;
                                            }) => {
                                                return (
                                                    <ProductCategoryItem
                                                        key={index}
                                                        product={item}
                                                        isProduct={
                                                            isProductSliderButActuallyCategorySlider
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Container>
                                )}
                            </>
                        );
                    }

                    const showButtons =
                        carouselItems && carouselItems.length > 1;
                    return (
                        <>
                            {showButtons && (
                                <ProductCategoryButton
                                    className={classnames(
                                        $.carouselButton,
                                        !isCategorySlider &&
                                            $.carouselButtonDetail,
                                    )}
                                    to="prev"
                                />
                            )}
                            {showButtons && (
                                <ProductCategoryButton
                                    className={classnames(
                                        $.carouselButton,
                                        !isCategorySlider &&
                                            $.carouselButtonDetail,
                                        $.isNext,
                                    )}
                                    to="next"
                                />
                            )}
                            {items && (
                                <FlickityCarousel.Wrapper
                                    className={$.carouselWrapper}
                                    items={items}
                                    // @TODO: Properly cast the item type as currently it is Unknown
                                    renderItem={({
                                        item,
                                        index,
                                    }: {
                                        item: ProductSlide;
                                        index: number;
                                    }) => {
                                        return (
                                            <ProductItem
                                                key={index}
                                                product={item}
                                                headTitle={item.titlePrefix}
                                                style={style}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </>
                    );
                })()}
            </FlickityCarousel>
        </div>
    );
};

export default ProductCategorySlider;
