import * as React from "react";
import classnames from "classnames";
import AppContext from "view/context/AppContext";
import $ from "./Title.module.scss";

type Props = {
    children: React.ReactNode;
    className?: any;
    ref?: any;
    weight?: string;
};

const Title: React.FunctionComponent<Props> = React.forwardRef<
    HTMLHeadingElement,
    Props
>((props, ref) => {
    const { children, className, weight } = props;
    const appContext = React.useContext(AppContext);
    let titlesInCaps;
    if (appContext && appContext.init && appContext.init.configuration) {
        const {
            init: { configuration },
        } = appContext;
        if (configuration) {
            ({ titlesInCaps } = configuration);
        }
    }

    switch (weight) {
        case "h1":
            return (
                <h1
                    className={classnames([
                        $.title,
                        titlesInCaps && $.titleHasCaps,
                        className,
                    ])}
                    ref={ref}
                >
                    {children}
                </h1>
            );
        case "h2":
        case "h3":
        default:
            return (
                <h2
                    className={classnames([
                        $.title,
                        titlesInCaps && $.titleHasCaps,
                        className,
                    ])}
                    ref={ref}
                >
                    {children}
                </h2>
            );
    }
});

export default Title;
