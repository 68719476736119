import axios from "axios";

export default async function fetchContactTypes(
    apiUrl: string,
    subscriptionKey?: string,
) {
    const url = `${apiUrl}/contacts/contacttypes`;
    const contactTypes = await axios(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey ?? "",
        },
    });
    return contactTypes;
}
