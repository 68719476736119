import * as React from "react";
import * as Flickity from "flickity";
import FlickityCarouselContext from "./FlickityCarousel.Context";

type ErrorCatcherOptions = {
    retry?: boolean;
};

function withErrorCatcher<P extends {}>(
    WrappedComponent: React.ComponentType<P>,
    opts: ErrorCatcherOptions = {},
) {
    return class ErrorCatcher extends React.Component<
        P,
        { hasError?: boolean }
    > {
        state = {
            hasError: false,
        };

        count = 0;

        private retry() {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if (this.count < 5) {
                        this.setState({ hasError: false });
                        this.count += 1;
                    }
                });
            });
        }

        componentDidCatch(err: any) {
            console.log("ErrorCatcher catched:", err);
            this.setState({ hasError: true }, () => {
                if (opts.retry) {
                    this.retry();
                }
            });
        }

        render() {
            if (this.state.hasError) {
                return null;
            }

            return <WrappedComponent {...this.props} />;
        }
    };
}

type Props<T> = {
    items: T[];
    renderItem(props: { item: T; index: number }): JSX.Element;
    className?: string;
    isCategorySlider?: boolean;
    scrollable?: boolean;
};

function FlickityCarouselWrapper<T>({
    items,
    renderItem,
    className,
    isCategorySlider,
    scrollable,
}: Props<T>) {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const context = React.useContext(FlickityCarouselContext);
    if (context === null)
        throw new Error("missing wrapping FlickityCarousel context");
    const wrapAround = (isCategorySlider && scrollable) || !isCategorySlider;
    const contain = isCategorySlider && !scrollable;

    React.useEffect(() => {
        if (containerRef.current) {
            const flickityInstance = new Flickity(containerRef.current, {
                accessibility: false,
                pageDots: false,
                wrapAround,
                contain,
                draggable: false,
                prevNextButtons: false,
                adaptiveHeight: false,
            });
            context.setFlickityInstance(flickityInstance);
            flickityInstance.resize();

            return () => {
                flickityInstance.destroy();
            };
        }
    }, [items]);

    return (
        <div ref={containerRef} className={className}>
            {items.map((item, index) => renderItem({ item, index }))}
        </div>
    );
}

export default withErrorCatcher(FlickityCarouselWrapper, {
    retry: true,
});
