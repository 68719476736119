import * as React from "react";
import { useInView } from "react-intersection-observer";
import classnames from "classnames";
import { Content } from "core/models/modules/Content";
import ReadMoreButton from "view/components/ReadMoreButton";
import Container from "view/components/Container";
import Title from "view/components/Title";
import $ from "./ContentAnimated.module.scss";

const ContentAnimated = (props: Content) => {
    const animationWrap = React.createRef<HTMLDivElement>();
    const bubbles = React.createRef<HTMLImageElement>();
    const animationImage = React.createRef<HTMLImageElement>();
    const [ref, inView] = useInView();
    const titleRef = React.createRef<HTMLHeadingElement>();
    const titleStyles = classnames([$.title, inView && $.titleAnimate]);
    const {
        title,
        text,
        ctaUrl,
        ctaLabel,
        colorBackground,
        image,
        imageOverlay,
        imageMobile,
    } = props;
    const [animationYOffset, setAnimationYOffset] = React.useState<
        undefined | number
    >();
    const saveYOffsetOfElement = () => {
        if (animationWrap && animationWrap.current) {
            const bodyRect = document.body.getBoundingClientRect();
            const elRect = animationWrap.current.getBoundingClientRect();
            const yOffsetEl = elRect.top - bodyRect.top;

            setAnimationYOffset(yOffsetEl);
        }
    };
    const animate = () => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const maxScrollArea = window.innerHeight / 2; // makes animation shorter
        const scrolled = scrolledSinceAnimationInView(scrollTop, maxScrollArea);
        const fade = (1 / maxScrollArea) * scrolled;
        const rotate = (-10 / maxScrollArea) * scrolled + 6; // initial css rotate value is 6
        const translate = (3 / maxScrollArea) * (scrolled - maxScrollArea); // translate needs to be between -3 and 0

        if (bubbles && bubbles.current) {
            bubbles.current.style.opacity = String(fade);
        }
        if (animationImage && animationImage.current) {
            animationImage.current.style.transform = `rotate(${rotate}deg)`;
        }
        if (titleRef && titleRef.current) {
            titleRef.current.style.opacity = String(fade);
            titleRef.current.style.transform = `translateX(${translate}rem)`;
        }
    };
    const scrolledSinceAnimationInView = (
        scrollTop: number,
        maxScrollArea: number,
    ) => {
        let scrolled =
            window.innerHeight - ((animationYOffset || 0) - scrollTop); // Number of pixels scrolled since in viewport

        if (scrolled <= 0) {
            scrolled = 0;
        }
        if (scrolled >= maxScrollArea) {
            scrolled = maxScrollArea;
        }
        return scrolled;
    };

    React.useEffect(() => {
        saveYOffsetOfElement();
    }, []);

    React.useEffect(() => {
        if (inView) {
            document.addEventListener("scroll", animate);
        }
        return () => {
            document.removeEventListener("scroll", animate);
        };
    });

    React.useEffect(() => {
        window.addEventListener("resize", saveYOffsetOfElement);

        return () => {
            window.removeEventListener("resize", saveYOffsetOfElement);
        };
    });

    return (
        <section
            className={$.base}
            style={{ backgroundColor: colorBackground }}
            data-testid="parent"
        >
            <Container className={$.baseWrap}>
                <div className={$.animationWrap} ref={ref}>
                    <div className={$.animation} ref={animationWrap}>
                        {imageMobile && (
                            <img
                                src={imageMobile.url}
                                title={imageMobile.title}
                                alt={imageMobile.title}
                                className={$.imageLeft}
                                ref={animationImage}
                                data-testid="image-mobile"
                            />
                        )}
                        {image && (
                            <img
                                src={image.url}
                                title={image.title}
                                alt={image.title}
                                className={$.imageRight}
                                data-testid="image"
                            />
                        )}
                        {imageOverlay && (
                            <img
                                src={imageOverlay.url}
                                title={imageOverlay.title}
                                alt={imageOverlay.title}
                                className={$.animationBubbles}
                                ref={bubbles}
                                data-testid="image-overlay"
                            />
                        )}
                    </div>
                </div>
                <div className={$.content}>
                    <div className={$.contentWrap}>
                        <Title
                            weight="h1"
                            ref={titleRef}
                            className={titleStyles}
                        >
                            {title}
                        </Title>
                        <div
                            className={$.text}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                        {ctaLabel && ctaUrl && (
                            <ReadMoreButton
                                link={ctaUrl}
                                text={ctaLabel}
                                invert={
                                    !!colorBackground &&
                                    colorBackground !== "#ffffff"
                                }
                            />
                        )}
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ContentAnimated;
