import { createContext } from "react";
import { Init, ShopAvailability } from "core/models";

export type AppContextType = {
    init: Init;
    modalOpen: boolean;
    setModalOpen: (bool: boolean) => void;
    modalInfo: ShopAvailability;
    setModalInfo: (obj: ShopAvailability) => void;
    showHeaderLogo: boolean;
    setShowHeaderLogo: (bool: boolean) => void;
    pageData: any;
    setPageData: (data: any) => void;
};

const AppContext = createContext<AppContextType | null>(null);
export default AppContext;
