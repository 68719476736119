import classnames from "classnames";
import { Hero as HeroModel } from "core/models";
import useWindowWidth from "view/hooks/useWindowWidth";
import { Helmet } from "react-helmet";
import { Button, Container, Image, ReadMoreButton } from "view/components";
import $ from "./Hero.module.scss";

const Hero: React.FC<HeroModel> = ({
    title,
    text,
    textBlockHideBackground,
    image: imageDesktop,
    imageMobile,
    ctaPrimaryUrl,
    ctaPrimaryLabel,
    ctaPrimaryStyle,
    ctaSecondaryUrl,
    ctaSecondaryLabel,
    ctaSecondaryStyle,
    alignment,
}) => {
    const MOBILE_WIDTH = 768;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= MOBILE_WIDTH;
    const imgWidth = isMobile ? 1125 : 1800;
    const hasButton = ctaPrimaryLabel || ctaSecondaryLabel;

    const heroImage = isMobile && imageMobile?.url ? imageMobile : imageDesktop;

    const getButtonBasedOnStyle = (
        ctaButton: "primary" | "secondary",
        style: string | undefined,
    ): JSX.Element => {
        if (style === "arrow") {
            return (
                <ReadMoreButton
                    text={
                        ctaButton === "primary"
                            ? ctaPrimaryLabel ?? ""
                            : ctaSecondaryLabel ?? ""
                    }
                    link={
                        ctaButton === "primary"
                            ? ctaPrimaryUrl ?? ""
                            : ctaSecondaryUrl ?? ""
                    }
                    className={classnames(
                        $.readMoreButton,
                        alignment === "Center" && $.center,
                        alignment === "Right" && $.right,
                    )}
                    invert
                />
            );
        }

        return (
            <Button
                text={
                    ctaButton === "primary"
                        ? ctaPrimaryLabel ?? ""
                        : ctaSecondaryLabel ?? ""
                }
                link={
                    ctaButton === "primary"
                        ? ctaPrimaryUrl ?? ""
                        : ctaSecondaryUrl ?? ""
                }
                className={classnames(
                    $.button,
                    alignment === "Center" && $.center,
                    alignment === "Right" && $.right,
                )}
                navy
            />
        );
    };

    return (
        <section
            className={classnames(
                $.hero,
                alignment === "Center" && $.center,
                alignment === "Right" && $.right,
            )}
            data-testid="hero"
        >
            {heroImage && heroImage.url && (
                <>
                    <Helmet>
                        <link
                            rel="preload"
                            href={`${heroImage.url}?w=${imgWidth}&q=80&fm=jpg&fl=progressive`}
                            as="image"
                        />
                    </Helmet>
                    <Image
                        placeholder={`${heroImage.url}?w=30&q=50&fm=jpg&fl=progressive`}
                        src={`${heroImage.url}?w=${imgWidth}&q=80&fm=jpg&fl=progressive`}
                        alt={heroImage.title}
                        className={$.image}
                        background
                    />
                </>
            )}

            <Container>
                {title && (
                    <h1
                        className={classnames(
                            $.title,
                            alignment === "Center" && $.center,
                            alignment === "Right" && $.right,
                        )}
                        data-testid="title"
                    >
                        {title}
                    </h1>
                )}

                {text && (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: text }}
                        data-testid="text"
                        className={classnames(
                            $.container,
                            $.text,
                            textBlockHideBackground && $.noBackground,
                        )}
                    />
                )}

                {hasButton && (
                    <div>
                        <div className={classnames($.buttonContainer)}>
                            {ctaPrimaryLabel &&
                                getButtonBasedOnStyle(
                                    "primary",
                                    ctaPrimaryStyle,
                                )}
                        </div>

                        <div className={classnames($.buttonContainer)}>
                            {ctaSecondaryLabel &&
                                getButtonBasedOnStyle(
                                    "secondary",
                                    ctaSecondaryStyle,
                                )}
                        </div>
                    </div>
                )}
            </Container>
        </section>
    );
};

export default Hero;
