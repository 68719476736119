import * as React from "react";
import { useInView } from "react-intersection-observer";
import classnames from "classnames";
import AppContext from "view/context/AppContext";
import { Link } from "view/components";
import $ from "./ReadMoreButton.module.scss";

interface Props {
    link: string;
    text: string;
    invert?: boolean;
    className?: string;
}

const ReadMoreButton = (props: Props) => {
    const appContext = React.useContext(AppContext);
    const { invert, link, text, className } = props;

    let ctaColor;

    if (appContext && appContext.init && appContext.init.configuration) {
        const {
            init: { configuration },
        } = appContext;
        if (configuration) {
            ({ ctaColor } = configuration);
        }
    }

    const buttonStyle = {
        backgroundColor: ctaColor,
    };

    const [ref, inView] = useInView({
        threshold: 0,
    });
    const backgroundStyles = classnames([
        $.iconBackground,
        invert && $.iconBackgroundInvert,
        inView && $.iconBackgroundAnimate,
    ]);
    const arrowStyles = classnames([$.iconArrow, invert && $.iconArrowInvert]);
    const textStyles = classnames([$.text, invert && $.textWhite]);

    return (
        <Link to={link} className={classnames($.base, className)}>
            <span className={textStyles}>{text}</span>
            <div className={$.icon} ref={ref}>
                <div
                    className={backgroundStyles}
                    data-testid="background"
                    style={buttonStyle}
                />
                <svg viewBox="0 0 9 13" className={arrowStyles}>
                    <path
                        d="M2.25 10.75l4.5-4.5-4.5-4.5"
                        strokeWidth="3"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
        </Link>
    );
};

export default ReadMoreButton;
