import * as React from "react";
import { ShopAvailability } from "core/models";
import arrowRight from "assets/icons/icon-arrowright.svg";
import arrowClose from "assets/icons/icon-close.svg";
import $ from "./Modal.module.scss";

interface Props extends ShopAvailability {
    closeModal: () => void;
}

const Modal = (props: Props) => {
    const { title, subTitle, table, closeModal } = props;

    return (
        <div className={$.base} data-testid="base">
            <section className={$.content}>
                <h2 className={$.title} data-testid="title">
                    {title}
                </h2>
                <p className={$.subTitle} data-testid="text">
                    {subTitle}
                </p>
                <button
                    className={$.closeButton}
                    onClick={closeModal}
                    data-testid="button"
                >
                    <img
                        src={arrowClose}
                        alt="Sluiten"
                        title="Sluiten"
                        className={$.closeImg}
                    />
                </button>
                {table && table.length && (
                    <ul className={$.list} data-testid="list">
                        {table.map((store) => (
                            <li
                                className={$.listItem}
                                key={store.key}
                                data-testid="list-item"
                            >
                                <a
                                    href={store.value}
                                    className={$.link}
                                    data-testid="link"
                                >
                                    <span
                                        className={$.storeName}
                                        data-testid="store-name"
                                    >
                                        {store.key}
                                    </span>
                                    <img
                                        src={arrowRight}
                                        alt={`Ga naar ${store.key}`}
                                        className={$.arrow}
                                    />
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
                {(!table || table.length === 0) && (
                    <p data-testid="message">
                        Er zijn op dit moment geen winkels waar dit product
                        wordt verkocht
                    </p>
                )}
            </section>
        </div>
    );
};

export default Modal;
