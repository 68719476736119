import * as React from "react";
import { debounce } from "lodash";
import ResizeObserver from "resize-observer-polyfill";

type Handler = (entry: ResizeObserverEntry) => void;

function createResizeObserver() {
    const handlers = new WeakMap<Element, Handler>();

    const observer = new ResizeObserver(
        debounce((entries) => {
            entries.forEach((entry) => {
                const handler = handlers.get(entry.target);
                if (handler) {
                    handler(entry);
                }
            });
        }, 300),
    );

    return {
        observer,
        add(el: Element, handler: Handler) {
            handlers.set(el, handler);
            observer.observe(el);
        },
        del(el: Element) {
            handlers.delete(el);
            observer.unobserve(el);
        },
    };
}

export default function useResizeObserver(
    ref: React.RefObject<null | Element>,
) {
    const [observerContext] = React.useState(createResizeObserver);
    const [entry, setEntry] = React.useState<null | ResizeObserverEntry>(null);

    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        const el = ref.current;
        if (el) {
            observerContext.add(el, setEntry);
            return () => {
                observerContext.del(el);
            };
        }
    }, [observerContext, ref]);

    return entry;
}
