import { MenuItem } from "core/models";
import { ImageVideoObject } from "./ImageVideoObject";

export interface Params {
    data: {
        settings: Settings;
    };
}

export interface Font {
    type: string;
    title: string;
    url: string;
}

export interface FontFamily {
    key: string;
    uriList: Font[];
}

interface NavItem {
    path: string;
    slug: string;
}

export interface Configuration {
    favicon: ImageVideoObject;
    title: string;
    tagline: string;
    seoDescription: string;
    seoKeywords: string;
    fonts: FontFamily[];
    logoMenu: ImageVideoObject;
    logoHeader: ImageVideoObject;
    logoMenuSize: "small" | "large";
    logoFooter: ImageVideoObject;
    primaryColorBackground: string;
    primaryColorFont: string;

    navigationInCaps: boolean;
    titlesInCaps: boolean;
    ctaColor: string;
    ctaLayout: "default" | "square" | "square-rounded";
    ctaInCaps: boolean;
    ctaFont: "primary" | "secondary";

    homepageSlug: string;
    productRootSlug: string;

    mainMenuStyle: "align-center" | "align-right";
    menuColorBackground: string;
    menuColorText: string;
    menuColorTextActive: string;
    menuBoxShadow: string;

    homepageUseImageHeader: boolean;
    homepageHeaderTextColor: string;
    image: ImageVideoObject;
    imageMobile: ImageVideoObject;
    videoHeader?: ImageVideoObject;

    footerColor: string;
    footerPrimaryColorBackground: string;
    footerPrimaryColorText: string;
    footerSecondaryColorBackground: string;
    footerSecondaryColorText: string;
    footerBoxShadow: boolean;
    backgroundImage?: ImageVideoObject;
    backgroundImageMobile?: ImageVideoObject;
    backgroundImageStyle: string | undefined;
}

export interface Settings {
    configuration: Configuration;
    mainMenu: MenuItem[];
    footerMenu: MenuItem[];
    socialMenu: MenuItem[];
    html: {
        footer: string;
        header: string;
    };
    productPaths: NavItem[];
    productCategoryPaths: NavItem[];
}

export class Init {
    configuration: Configuration;

    mainMenu: MenuItem[];

    footerMenu: MenuItem[];

    socialMenu: MenuItem[];

    html: {
        footer: string;
        header: string;
    };

    productPaths: NavItem[];

    categoryPaths: NavItem[];

    constructor(data: Params) {
        const { settings } = data.data;
        const {
            configuration,
            mainMenu,
            footerMenu,
            socialMenu,
            html,
            productPaths,
            productCategoryPaths,
        } = settings;
        this.configuration = configuration;
        this.mainMenu = mainMenu;
        this.footerMenu = footerMenu;
        this.socialMenu = socialMenu;
        this.html = html;
        this.productPaths = productPaths;
        this.categoryPaths = productCategoryPaths;
    }
}
