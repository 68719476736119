export default function calculateIndex(
    till: number,
    current: number,
    offset: number,
) {
    const calc = (current + offset) % till;
    if (calc < 0) {
        return till + calc;
    }
    return Math.max(calc, 0);
}
