import { Module } from "./Module";

export interface PageParams {
    title: string;
    titleVisible: boolean;
    slug: string;
    contentType: string;
    moduleReferences: Module[];
    seoDescription: string;
    seoKeywords: string;
}

export class Page {
    title: string;

    titleVisible: boolean;

    slug: string;

    contentType: string;

    moduleReferences: Module[];

    seoDescription: string;

    seoKeywords: string;

    constructor(data: PageParams) {
        const {
            title,
            titleVisible,
            slug,
            contentType,
            moduleReferences,
            seoDescription,
            seoKeywords,
        } = data;
        this.title = title;
        this.titleVisible = titleVisible;
        this.slug = slug;
        this.contentType = contentType;
        this.moduleReferences = moduleReferences;
        this.seoDescription = seoDescription;
        this.seoKeywords = seoKeywords;
    }
}
