import * as React from "react";
import { RouteComponentProps } from "react-router";
import fetchPage from "core/services/api/page";
import fetchCategory from "core/services/api/category";
import {
    Header,
    ModuleHandler,
    Head,
    Loader,
    PageNotFound,
} from "view/components";
import AppContext, { AppContextType } from "view/context/AppContext";
import fetchProduct from "core/services/api/product";
import { stripTrailingSlash } from "core/utils/stripTrailingSlash";
import { stripLeadingSlash } from "core/utils/stripLeadingSlash";
import ConfigContext, { ConfigContextType } from "../context/ConfigContext";
import $ from "./Page.module.scss";

function useFetchData(
    pathname: string,
    configContext: ConfigContextType,
    appContext: AppContextType | null,
    search: string,
) {
    const [loading, setLoading] = React.useState(true);

    const getPreviewParam = (): string => {
        if (search != null && search !== undefined && search !== "") {
            const searchParams = new URLSearchParams(search);
            if (searchParams !== undefined) {
                const previewToken = searchParams.get("preview");
                if (previewToken != null) {
                    return previewToken;
                }
            }
        }

        return "";
    };

    const fetchData = React.useMemo(
        () => async () => {
            if (
                !appContext ||
                !appContext.init ||
                !configContext ||
                !location
            ) {
                return null;
            }

            setLoading(true);

            const { init, pageData, setPageData } = appContext;
            const previewParam = getPreviewParam();
            const { productPaths, categoryPaths } = init;
            const data = pageData || {};

            // exit if content already exists
            if (data[pathname]) {
                // triggers rerender of page content
                setLoading(false);
                return null;
            }

            // determine type to fetch
            const homeSlug = "home";
            const slug = stripTrailingSlash(
                pathname === "/" ? homeSlug : pathname,
            );
            const isCategoryPath =
                categoryPaths &&
                categoryPaths.find(
                    (category) => stripTrailingSlash(category.path) === slug,
                );
            const isProductPath =
                productPaths &&
                productPaths.find(
                    (product) => stripTrailingSlash(product.path) === slug,
                );
            let response;
            if (isCategoryPath) {
                response = await fetchCategory(
                    configContext,
                    isCategoryPath.slug,
                    previewParam,
                );
            } else if (isProductPath) {
                response = await fetchProduct(
                    configContext,
                    isProductPath.slug,
                    previewParam,
                );
            } else {
                response = await fetchPage(
                    configContext,
                    stripLeadingSlash(slug),
                    previewParam,
                );
            }

            if (response) {
                // save reponse to key
                data[pathname] = response;
                // update context
                setPageData && setPageData(data);
            }
            // remove spinner
            setLoading(false);
        },
        [pathname, configContext, appContext],
    );

    return { loading, fetchData };
}

type Props = RouteComponentProps<any>;

const Page: React.FunctionComponent<Props> = (props) => {
    const {
        location: { pathname, search },
    } = props;
    const configContext = React.useContext(ConfigContext);
    const appContext = React.useContext(AppContext);
    const { loading, fetchData } = useFetchData(
        pathname,
        configContext,
        appContext,
        search,
    );

    React.useEffect(() => {
        fetchData();
    }, [pathname, fetchData, configContext, appContext]);

    if (!appContext) {
        return null;
    }

    const { pageData, init } = appContext;
    const homepageUseImageHeader =
        init && init.configuration && init.configuration.homepageUseImageHeader;
    let modules;
    let pageTitle;
    let pageTitleVisible;
    let pageSeoDescription;
    let pageSeoKeywords;
    const data = pageData || {};
    if (data[pathname]) {
        const {
            moduleReferences,
            title,
            titleVisible,
            seoDescription,
            seoKeywords,
        } = data[pathname];
        modules = moduleReferences;
        pageTitle = title;
        pageTitleVisible = titleVisible;
        pageSeoDescription = seoDescription;
        pageSeoKeywords = seoKeywords;
    }

    const showHeader = pathname === "/" && homepageUseImageHeader;

    if (loading) {
        return (
            <div className={$.loaderWrap}>
                <Loader />
            </div>
        );
    }

    if (modules && modules.length > 0) {
        return (
            <>
                <Head
                    subTitle={pageTitle}
                    pageSeoDescription={pageSeoDescription}
                    pageSeoKeywords={pageSeoKeywords}
                />
                {showHeader && <Header />}
                <ModuleHandler
                    modules={modules}
                    pageTitle={pageTitle}
                    pageTitleVisible={pageTitleVisible}
                />
            </>
        );
    }

    return <PageNotFound />;
};

export default Page;
