import * as React from "react";
import CarouselItem from "./Carousel.Item";
import CarouselWrapper from "./Carousel.Wrapper";
import CarouselNavigationButton from "./Carousel.NavigationButton";
import calculateIndex from "./calculateIndex";
import CarouselContext from "./Carousel.Context";

type Props = {
    childrenCount: number;
    animationDuration: number;
    className?: string;
    shownItems?: number;
    initialCurrentIndex?: number;
    children: React.ReactNode | React.ReactNodeArray;
};
type ComponentType = React.FunctionComponent<Props> & {
    Item: typeof CarouselItem;
    Wrapper: typeof CarouselWrapper;
    NavigationButton: typeof CarouselNavigationButton;
};

const Carousel: ComponentType = ({
    className,
    children,
    childrenCount,
    animationDuration,
}: Props) => {
    const timeoutIdRef = React.useRef(0);
    const canNavigateRef = React.useRef(true);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const moveIndex = (offset: number) => {
        if (canNavigateRef.current) {
            setCurrentIndex((lastCurrentIndex) =>
                calculateIndex(childrenCount, lastCurrentIndex, offset),
            );
            canNavigateRef.current = false;
            timeoutIdRef.current = window.setTimeout(() => {
                canNavigateRef.current = true;
            }, animationDuration);
        }
    };
    const navigatePrev = React.useCallback(() => moveIndex(-1), [moveIndex]);
    const navigateNext = React.useCallback(() => moveIndex(1), [moveIndex]);
    const context: React.ContextType<typeof CarouselContext> = {
        currentIndex,
        childrenCount,
        navigatePrev,
        navigateNext,
    };

    React.useEffect(
        () => () => {
            clearTimeout(timeoutIdRef.current);
        },
        [],
    );

    if (childrenCount <= 0) {
        return null;
    }

    return (
        <CarouselContext.Provider value={context}>
            <div className={className}>{children}</div>
        </CarouselContext.Provider>
    );
};

Carousel.Item = CarouselItem;
Carousel.Wrapper = CarouselWrapper;
Carousel.NavigationButton = CarouselNavigationButton;

export default Carousel;
