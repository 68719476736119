import * as React from "react";

const scriptRegExp = /<script.*?(src="(.*?)")?>(.*?)<\/script>/s;

interface Result {
    html: string;
    javascript: string[];
    scripts: string[];
}

function parseHtml(
    html: string,
    javascript: string[] = [],
    scripts: string[] = [],
): Result {
    const jsResult = scriptRegExp.exec(html);
    if (jsResult) {
        const [block, srcBlock, scriptSrc, code] = jsResult;
        const parsedHtml = html.replace(block, "");
        return parseHtml(
            parsedHtml,
            javascript.concat(code),
            scripts.concat(scriptSrc),
        );
    }
    return {
        html,
        javascript: javascript.filter(Boolean),
        scripts: scripts.filter(Boolean),
    };
}

function startParseHtml(html: string) {
    const result = parseHtml(html);
    return result;
}

const addedScriptsCache = new Map<string, boolean>();
function addScriptToTheDocument(scriptSrc: string) {
    if (!addedScriptsCache.get(scriptSrc)) {
        const scriptEl = document.createElement("script");
        scriptEl.async = true;
        scriptEl.src = scriptSrc;
        document.body.appendChild(scriptEl);
        addedScriptsCache.set(scriptSrc, true);
    }
}

const HtmlCode = (props: { html: string }) => {
    const { html } = props;
    const parsed = React.useMemo(() => startParseHtml(html), [html]);
    const [pending, setPending] = React.useState(false);

    React.useEffect(() => {
        try {
            // eval is not recommanded but due time constraints used until
            // we have found a way to use scripts in the api without calling
            // it this way
            //
            // @patrick @kostas
            setPending(true);
            parsed.scripts.forEach(addScriptToTheDocument);
            setTimeout(() => {
                parsed.javascript.forEach((code) => {
                    // tslint:disable-next-line:no-eval
                    eval(code);
                });
                setPending(false);
            }, 1000);
        } catch (e: any) {
            console.error(e.message);
        }
    }, [parsed]);

    return parsed.html ? (
        <div dangerouslySetInnerHTML={{ __html: parsed.html }} />
    ) : (
        <p>No result.</p>
    );
};

export default HtmlCode;
