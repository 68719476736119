import Page from "view/containers/Page";

interface Route {
    path: string;
    exact: boolean;
    component: React.ComponentType<any>;
    [prop: string]: any;
}

const routes: Route[] = [
    {
        path: "/",
        component: Page,
        exact: true,
    },
    {
        path: "*",
        component: Page,
        exact: false,
    },
];

export default routes;
