import * as React from "react";
import Analytics from "core/services/analytics";
import { Link } from "react-router-dom";

interface Props {
    src: string;
    alt?: string | null;
    className?: string;
}

const Logo = ({ src, alt, ...props }: Props) => {
    if (!src) return null;
    const to = "/";
    const clickHandler = React.useCallback(() => {
        Analytics.event("logo", "click", to);
    }, []);

    const imgSrc = `${src}?w=300&h=300&q=100`;

    return (
        <Link to={to} onClick={clickHandler}>
            <img src={imgSrc} alt={alt || undefined} {...props} />
        </Link>
    );
};

export default Logo;
