/* eslint-disable @typescript-eslint/camelcase */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dataLayer: any;

const Analytics = {
    pageView(path: string, title?: string) {
        const pageData = {
            event: "pageview",
            page: {
                path,
                title,
            },
        };
        // TAG MANAGER
        dataLayer.push(pageData);
    },

    event(
        eventCategory: string,
        eventAction: string,
        eventLabel: string,
        eventValue?: string,
    ) {
        const eventData = {
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
            event: `${eventAction} ${eventCategory}`,
        };
        // TAG MANAGER
        dataLayer.push(eventData);
    },
};

export default Analytics;
