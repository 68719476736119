import { Base } from "./modules/Base";

export interface Form extends Base {
    moduleType: "moduleContact";
    contactType: "Customers" | "Leads" | "HorecaHeatmap" | "Contactform";
    title: string;
    postUri: string;
    contactTypes?: string[];
    products?: string[];
    fields?: FormFields[];
    colorBackground?: string;
    formPostSubscriptionKey?: string;
}

export interface FormFields {
    name: string;
    type: string;
    options?: FormOptions[];
    placeholder: string;
    required: boolean;
    order?: number;
    labelText?: string;
}

export interface FormOptions {
    value: string;
    label: string;
}

export interface ContactTypeEntity {
    id: string;
    name: string;
    label: string;
}

function getContactTypeName(name: string) {
    if (name === "Product vraag/klacht") return "product";
    if (name === "Actie/Promotie vraag/klacht") return "specialOffer";
    return "general";
}

export class ContactType {
    id: string;

    name: "product" | "specialOffer" | "general";

    value: string;

    label: string;

    constructor(contactType: ContactTypeEntity) {
        this.id = contactType.id;
        this.name = getContactTypeName(contactType.name);
        this.value = contactType.name;
        this.label = contactType.label;
    }
}
