import * as React from "react";
import classnames from "classnames";
import { MenuItem, Configuration } from "core/models";
import AppContext from "view/context/AppContext";
import getCurrentYear from "core/utils/getCurrentYear";
import { Logo, Link, Container } from "view/components";
import $ from "./Footer.module.scss";

const Footer = () => {
    const appContext = React.useContext(AppContext);
    if (!appContext) return null;

    let title: Configuration["title"] | null = null;
    let logoFooter: Configuration["logoFooter"] | null = null;
    let menuItems: MenuItem[] | null = null;
    let socialMenuItems: MenuItem[] | null = null;
    let footerPrimaryColorBackground:
        | Configuration["footerPrimaryColorBackground"]
        | null = null;
    let footerPrimaryColorText: Configuration["footerPrimaryColorText"] | null =
        null;
    let footerSecondaryColorBackground:
        | Configuration["footerSecondaryColorBackground"]
        | null = null;
    let footerSecondaryColorText:
        | Configuration["footerSecondaryColorText"]
        | null = null;
    let footerBoxShadow;
    const { init } = appContext;
    if (init) {
        const { footerMenu, socialMenu, configuration } = init;
        menuItems = footerMenu;
        socialMenuItems = socialMenu;
        if (configuration) {
            ({
                title,
                logoFooter,
                footerPrimaryColorBackground,
                footerPrimaryColorText,
                footerSecondaryColorBackground,
                footerSecondaryColorText,
                footerBoxShadow,
            } = configuration);
        }
    }

    const footerClassnames = classnames(
        $.footer,
        footerBoxShadow && $.footerShadow,
    );
    const primaryFooterStyle: React.CSSProperties =
        footerPrimaryColorBackground || footerPrimaryColorText
            ? {
                  backgroundColor: footerPrimaryColorBackground || undefined,
                  color: footerPrimaryColorText || undefined,
              }
            : {};
    const secondaryFooterStyle = footerSecondaryColorBackground
        ? {
              backgroundColor: footerSecondaryColorBackground,
          }
        : {};
    const smallStyle = footerSecondaryColorText
        ? {
              color: footerSecondaryColorText,
          }
        : {};

    const year = getCurrentYear();

    return (
        <footer className={footerClassnames}>
            <div className={$.footerMenu} style={primaryFooterStyle}>
                <Container className={$.container}>
                    {logoFooter && logoFooter.url && (
                        <Logo
                            src={logoFooter.url}
                            alt={title}
                            className={$.logo}
                        />
                    )}

                    {menuItems && (
                        <ul id="footer-navigation">
                            {menuItems.map((item: MenuItem, index: number) => (
                                <li key={index}>
                                    <Link to={item.path || item.externalUrl}>
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </Container>
            </div>

            <div className={$.footerInfo} style={secondaryFooterStyle}>
                <Container className={$.container}>
                    <ul>
                        {socialMenuItems &&
                            socialMenuItems.map((item) => (
                                <React.Fragment key={item.id}>
                                    {item.media && item.media.url && (
                                        <li>
                                            <Link to={item.externalUrl}>
                                                {item.media &&
                                                    item.media.url && (
                                                        <img
                                                            src={item.media.url}
                                                            alt={item.title}
                                                            title={item.title}
                                                        />
                                                    )}
                                            </Link>
                                        </li>
                                    )}
                                </React.Fragment>
                            ))}
                    </ul>

                    <small style={smallStyle}>
                        &copy; {year} {title}
                    </small>
                </Container>
            </div>
        </footer>
    );
};

export default Footer;
