import * as React from "react";
import { Helmet } from "react-helmet";
import AppContext from "../context/AppContext";

function createTitle(title: string, subTitle?: string) {
    let result = title;
    if (subTitle) {
        result = `${subTitle} - ${title}`;
    }
    return result;
}

type Props = {
    subTitle?: string;
    pageSeoDescription?: string;
    pageSeoKeywords?: string;
};

export default function Head(props: Props) {
    const appContext = React.useContext(AppContext);
    if (!appContext) {
        return null;
    }
    const {
        title,
        seoKeywords: contextSeoKeywords,
        seoDescription: contextSeoDescription,
        logoMenu,
        favicon,
    } = appContext.init.configuration;
    const { subTitle, pageSeoDescription, pageSeoKeywords } = props;
    const seoDescription = pageSeoDescription || contextSeoDescription;
    const seoKeywords = pageSeoKeywords || contextSeoKeywords;

    return (
        <Helmet>
            {title && <title>{createTitle(title, subTitle)}</title>}
            {title && (
                <meta
                    property="og:title"
                    content={createTitle(title, subTitle)}
                />
            )}
            {seoDescription && (
                <meta name="description" content={seoDescription} />
            )}
            {seoDescription && (
                <meta property="og:description" content={seoDescription} />
            )}
            {seoKeywords && <meta name="keywords" content={seoKeywords} />}
            {logoMenu && logoMenu.url && (
                <meta property="og:image" content={logoMenu.url} />
            )}
            {<meta property="og:url" content={window.location.href} />}
            {<link rel="canonical" href={window.location.href} />}
            {<meta property="og:type" content={"website"} />}
            {favicon && <link rel="shortcut icon" href={favicon.url} />}
        </Helmet>
    );
}
