import * as React from "react";
import classnames from "classnames";
import { Link } from "view/components";
import Analytics from "core/services/analytics";
import AppContext from "view/context/AppContext";
import $ from "./Button.module.scss";

interface Props {
    text: string;
    link?: string;
    onClick?: () => void;
    navy?: boolean;
    white?: boolean;
    lightgrey?: boolean;
    bluebird?: boolean;
    className?: string;
    style?: any;
    type?: "submit" | "reset" | "button";
}

const Button = (props: Props) => {
    const appContext = React.useContext(AppContext);
    let ctaInCaps;
    let ctaColor;
    let ctaLayout;
    let ctaFont;
    if (appContext && appContext.init && appContext.init.configuration) {
        const {
            init: { configuration },
        } = appContext;
        if (configuration) {
            ({ ctaInCaps, ctaColor, ctaLayout, ctaFont } = configuration);
        }
    }
    const {
        navy,
        white,
        lightgrey,
        bluebird,
        text,
        link,
        onClick,
        className,
        style,
        type,
    } = props;

    const buttonStyle = {
        backgroundColor: ctaColor,
        ...style,
    };

    const buttonStyles = classnames([
        $.button,
        navy && $.buttonNavy,
        white && $.buttonWhite,
        lightgrey && $.buttonLightGrey,
        bluebird && $.buttonBluebird,
        ctaInCaps && $.buttonHasCaps,
        ctaLayout === "square" && $.buttonIsSquare,
        ctaLayout === "square-rounded" && $.buttonIsSquareRounded,
        ctaFont === "primary" && $.buttonUsePrimaryFont,
        ctaFont === "secondary" && $.buttonUseSecondaryFont,
        className,
    ]);

    if (link) {
        return (
            <Link
                to={link}
                className={buttonStyles}
                data-testid="button"
                style={buttonStyle}
            >
                {text}
            </Link>
        );
    }

    const clickHandler = React.useCallback(() => {
        Analytics.event("button", "click", text, link);

        if (onClick) {
            onClick();
        }
    }, []);

    return (
        <button
            className={buttonStyles}
            data-testid="button"
            onClick={clickHandler}
            role="button"
            style={buttonStyle}
            type={type || "button"}
        >
            {text}
        </button>
    );
};

export default Button;
